@use "sass:map";
@import "../../../global.scss";

.aiAssistant {
	width: 512px;
	max-width: 100%;
	padding: 32px 0;
	min-height: 150px;

	.loadingContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	> :global(.space + .space) {
		margin-top: 24px;
	}

	.header {
		position: relative;
		display: flex;
		justify-content: space-between;
		padding: 0 32px;

		.title {
			flex: 1;
			display: flex;
			align-items: center;

			h4 {
				padding-left: 8px;
			}
		}

		.closeButton {
			position: absolute;
			right: 25px;
			top: 0;
			bottom: 0;
		}
	}

	.promptActions {
		display: flex;
		justify-content: space-between;
	}

	.inputRow {
		> :global(.space + .space) {
			margin-top: 0;
			margin-left: 8px;
		}
	}
	.aiCaptionTitle {
		margin-bottom: 4px;
	}

	.aiCaptionContainer {
		padding: 16px;
		@include background("grey-200");
		@include radius(16px);

		.captionCount {
			margin-left: auto;
		}
	}
}

.actionForm {
	padding: 0 32px;
}

.aiTerms {
	max-height: 350px;
	overflow: auto;
	padding: 0 32px;
}

.actions {
	padding: 0 32px;
}
