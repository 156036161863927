@use "sass:map";
@import "../../global.scss";

.container {
	z-index: map.get($zIndices, "modal-container");
	.text {
		@include background("black");
		@include color("white");
		display: block;
		border-radius: 8px;
		min-height: 34px;
		padding: 8px 16px;
		text-align: center;
	}
}

.child {
	display: flex;
	width: fit-content;
}

.arrowContainer {
	position: relative;
}

.triangle {
	height: 12px;
	width: 12px;
	@include background("black");
	position: absolute;
	margin: -6px;
	transform: rotate(135deg);
	border-radius: 0 0.25em 0 0;
	&.top {
		transform: rotate(135deg);
	}
	&.right {
		transform: rotate(-135deg);
	}
	&.left {
		transform: rotate(45deg);
	}
	&.bottom {
		transform: rotate(-45deg);
	}
	&.none {
		display: none;
	}
}
