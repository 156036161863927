@use "sass:map";
@import "../../global.scss";

.headerContainer {
	.radioGroup {
		flex-direction: row;
		padding-bottom: 8px;
	}

	.timeContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;

		@include svgPathColor("blue-primary");

		.dateTimeSpan {
			margin-right: 12px;
			@include color("grey-600");

			&.dateTimeSet {
				@include color("black");
			}
		}
	}
	.list {
		display: grid;
		grid-gap: 8px;
		.service {
			display: grid;
			grid-template-columns: 1fr 90px 80px 90px 1fr;
			align-items: center;
			gap: 8px;
		}
	}
}
