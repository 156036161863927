@use "sass:map";
@import "../../global.scss";

.tab {
	cursor: pointer;
	padding-bottom: 22px;

	&.active {
		@include border($color: "pink-primary", $where: "bottom");
		@include color("pink-primary");

		&:hover {
			@include color("pink-dark", $borderColor: "pink-dark");
		}
	}

	& {
		margin-right: 32px;
	}
}
