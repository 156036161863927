@import "../../../global.scss";

.popularPostsModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px 0;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;

	> :first-child {
		padding: 0 24px;
	}

	.content {
		width: 1000px;
		height: 100%;
		max-height: 700px;
		min-height: 500px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;

		.toolbar {
			padding: 0 24px;
		}

		.postsList {
			padding: 0 10px 0 24px;
			overflow: auto;
			flex: 1;
			.post {
				display: flex;
				align-items: flex-start;
				padding: 8px 48px 8px 16px;
				justify-content: space-between;
				@include boxShadow($alpha: 0.14);
				@include radius;
				gap: 24px;
				height: max-content;
				margin-bottom: 8px;
				@include tablet-and-phone {
					flex-direction: column;
				}

				&:nth-child(odd) {
					@include background("grey-100");
				}

				.statsContainer {
					display: flex;
					gap: 24px;
					padding: 0 16px 0 8px;
					flex: 1;
					justify-content: space-between;
					align-items: center;

					.postStats {
						display: flex;
						flex-direction: column;
						text-align: center;
					}
				}

				.image {
					height: 88px;
					width: 128px;
					img {
						height: 88px;
						width: 128px;
						@include radius;
						object-fit: cover;
					}
				}
				.text {
					width: 45%;
					height: 100%;
					line-height: 100% !important;

					.title {
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						white-space: pre-wrap;
					}

					.description {
						text-overflow: unset;
						white-space: pre-wrap;
						display: block;
					}
				}

				@include phone {
					flex-direction: column;
					text-align: center;
				}
			}
		}
	}

	.loader {
		display: flex;
		justify-content: center;
	}
}
