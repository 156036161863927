@import "../../global.scss";

.container {
	@include color("grey-600");

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 40px;
	max-width: 386px;
	margin: auto;
	gap: 8px;
}

.emptyIcon {
	@include color("grey-300", $svg: true);
}
