@import "../../global.scss";

.filterOptions {
	@include background("grey-200");
	display: flex;
	padding: 4px;
	align-items: center;
	border-radius: 12px;
	gap: 8px;
}
.container {
	.top {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 12px;
	}

	.items {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 32px;
		margin: 24px 0 36px 0;
		row-gap: 16px;
		width: 100%;

		.header {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 16px;

			.status {
				@include background("grey-200");
				border-radius: 15px;
				@include color("grey-600");
				font-weight: 600;
				padding: 5px 20px;
			}
		}

		.time {
			align-self: center;
		}

		@include phone {
			display: flex;
			flex-direction: column;
		}
	}

	.type {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
}

.card {
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	row-gap: 16px;

	.divider {
		margin: 0;
	}

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.title {
			display: flex;
			flex-direction: row;
			column-gap: 8px;
			align-items: center;
			.userId {
				@include color("black-dark");
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 130%;
			}
			.text {
				display: flex;
				flex-direction: column;
				.createdAt {
					white-space: nowrap;
					@include color("grey-600");
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
				}

				.recommendedAt {
					white-space: nowrap;
					@include color("pink-primary");
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
				}
			}
		}
	}
	.clickIcon {
		fill: #818b9a;
	}
	.clicksBtn {
		display: flex;
		height: 24px;
		align-items: center;
		.clicks {
			@include color("grey-600");
			font-size: 14px;
			font-weight: 600;
		}

		button {
			display: flex;
			flex-flow: row-reverse;
			gap: 4px;
			padding: 0px;
		}
	}
}

.showingContainer {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	margin: 0 5px;
	gap: 4px;
	min-width: 80px;
	max-height: 45px;
	overflow-y: auto;

	align-items: center;

	.badge {
		max-width: 65%;
	}
	.bookmarkCount {
		max-width: 35%;
	}
}

.postContent {
	@include background("grey-100");
	@include border("grey-300");
	border-radius: 8px;
	display: flex;
	align-items: center;
	gap: 16px;
	overflow: hidden;
	min-height: 96px;
	cursor: pointer;

	@include phone {
		flex-wrap: wrap;

		.postContentText {
			margin: 0 8px;
		}
	}

	.postContentText {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
	.postUrl {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	.image {
		display: flex;
		width: 128px;
		flex-shrink: 0;
		align-self: stretch;
		img {
			width: 100%;
			object-fit: cover;
			border-radius: 8px 0px 0px 8px;
		}
	}
}

.rssPostContent {
	border-radius: 8px;
	display: flex;
	align-items: flex-start;
	gap: 16px;
	overflow: hidden;
	cursor: pointer;

	@include phone {
		flex-wrap: wrap;

		.postContentText {
			margin: 0 8px;
			min-width: 200px;
			* {
				white-space: normal;
			}
		}
	}

	.postContentText {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		flex: 1;
	}
	.image {
		display: flex;
		height: 152px;
		width: 228px;
		flex-shrink: 0;
		img {
			width: 100%;
			object-fit: cover;
			border-radius: 8px;
		}
	}
}

.postContent,
.rssPostContent {
	.mediaContainer {
		position: relative;
		display: flex;
		align-self: stretch;
		.countBadge {
			position: absolute;
			top: calc(50% - 16px);
			right: 6px;
			min-width: 32px;
			height: 32px;
			border-radius: 16px;
			@include background("black", $alpha: 0.8);
			color: #fff;
			text-align: center;
			align-content: center;
			pointer-events: none;
		}
	}
}

.panelContainer {
	overflow-y: auto;
	max-height: 100%;
}

.panel {
	@include background("white");
	@include boxShadow($alpha: 0.04);
	padding: 16px 0;
	@include border($radius: true, $color: "grey-300");

	.header {
		padding: 0px 16px;
		flex-direction: column;
		position: relative;

		.loader {
			position: absolute;
			right: 24px;
			top: -16px;
		}
	}
	.feedButton {
		margin: 0px 16px;
	}
}

.postHeader {
	display: flex;
	align-items: center;
	gap: 8px;
}

.userPanel {
	padding: 16px;

	.achievements {
		flex: 1;
	}
	.badges {
		display: flex;
		padding-right: 8px;
	}
	.badges > div {
		margin-right: -8px;
	}

	.roleAndLevel {
		margin-top: 4px;
		display: flex;
		gap: 8px;
		align-items: center;
	}
	.stats {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		&.twoColumn {
			grid-template-columns: 0.25fr 0.75fr;
		}
	}
	.noSpacing {
		margin-top: 0;
	}
	.progressFill {
		@include color("grey-300", $background: true);
		border-radius: 99px 99px 99px 99px;
		padding: 2px;
		margin-bottom: 2px;
		.progress {
			border-radius: 99px 0 99px 99px;
			@include color("blue-primary", $background: true);
			height: 5px;
		}
	}
}

.createPost {
	cursor: pointer;
	display: flex;
	gap: 8px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.scheduledPostsContainer {
	padding: 16px;

	.scheduledPost {
		&.failedPost {
			@include background("pink-primary", $alpha: 0.01);
			@include border($color: "pink-primary-alpha", $radius: true);
		}

		@include background("grey-100");
		@include border($color: "grey-200", $radius: true);
		padding: 16px;
		.postDetails {
			margin-top: 8px;
			justify-content: space-between;
			gap: 8px;
			position: relative;

			.detailsGroup {
				display: flex;
				gap: 8px;
				padding: 4px 0;
				> div {
					margin: 0;
				}
				> p {
					white-space: nowrap;
				}
			}

			.buttons {
				visibility: hidden;
				position: absolute;
				right: 0;
				padding-left: 10px;
				@include background("grey-100");
			}
		}
		&:hover {
			.buttons {
				visibility: visible;
			}
		}
		img {
			border-radius: 7.5px;
			width: 56px;
			height: 56px;
			object-fit: cover;
		}
		.shareTitle {
			flex: 1;
		}
		.postScheduledTime {
			@include color("pink-primary");
		}
	}

	.groupDate {
		margin: 16px 0;
		display: block;
	}

	.clickable {
		cursor: pointer;
	}
}

.filterEmpty {
	display: flex;
	padding: 40px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 16px;
}

.failedPostModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 24px;

	.content {
		width: 420px;
		height: 100%;
		max-height: 700px;
		min-height: 400px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		.header {
			margin-bottom: 16px;
			justify-content: space-between;
			width: 100%;
			> * {
				flex: 1;
			}
		}

		.container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
		}
		.card {
			width: 312px;
			display: flex;
			flex-direction: column;
			padding: 24px;
			row-gap: 8px;
			box-shadow: none;
			@include border($color: "grey-300", $radius: true);

			& + & {
				margin-top: 0;
			}

			.status {
				@include background("grey-300");
				@include border($radius: true, $width: 0);
				align-self: center;
				padding: 4px 16px;

				&.approved {
					@include background("blue-100");
					@include color("blue-primary");
				}
			}

			.articleContainer {
				display: flex;
				@include border($radius: true);
				height: 120px;

				> .video,
				img {
					width: 100%;
					object-fit: cover;
					border-radius: 14px;
				}
			}

			.networkInfo {
				display: flex;
				align-items: center;
				gap: 8px;

				> span {
					line-height: 100%;
				}
			}
		}

		.error {
			background: #fcdae7;
			@include color("red-primary");
			padding: 16px 24px;
			border-radius: 8px;
		}

		.separator {
			@include border($where: "top");
			width: 100%;
		}
	}

	.footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 16px;
		> div {
			margin: 0;
		}
	}
}
