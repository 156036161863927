@use "sass:map";
@import "../../../../global.scss";

.collectionForm {
	display: flex;
	flex-direction: column;
	min-width: 450px;
	position: relative;
	background: #fff;
	overflow: hidden auto;
	max-height: calc(100% - 50px);
	@include radius($radius: 16px);
	@include boxShadow($alpha: 0.04);

	.collectionSettings {
		padding: 40px 40px 32px 40px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		@include border($where: "bottom", $color: "grey-300");

		.ownerStatusContainer {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.ownerSelect {
				height: 32px;
				padding: 0 8px 0 1px;
				@include border($radius: 13px, $color: "grey-400");
			}
		}

		.buttonsContainer {
			display: flex;
			gap: 16px;
			align-items: center;
		}

		.statusBadge {
			height: 24px;
		}
		.title {
			@include border($color: "grey-400", $where: bottom);
		}

		.recipients {
			> div {
				@include border($color: "grey-400", $where: bottom);
				> div {
					border: none;
				}
			}
		}

		.messageInput {
			@include border($color: "grey-400", $where: bottom);
		}

		.autoLikeScheduledContainer {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			align-items: center;

			> div {
				margin: 0;
			}
			.scheduleButton {
				width: fit-content;
				@include border($color: "grey-400");
				border-radius: 16px !important;
				button {
					height: 32px;
					padding: 7px 16px 7px 8px;
				}
			}
			.autoLikeBtn {
				button {
					height: 32px;
					padding: 11px 8px 11px 12px;
				}

				border-radius: 16px !important;
				@include border($color: "grey-400");

				.autoLikeContent {
					display: flex;
					align-items: center;
					gap: 8px;
					@include radius($radius: 13px);

					> div {
						* {
							line-height: normal;
						}
						display: flex;
						align-items: center;
						gap: 8px;
						padding-left: 8px;
						@include border($where: "left", $color: "grey-400");
					}
				}
			}
		}
	}

	.posts {
		padding: 16px 0 32px;
		background: #fafbfc;

		.postData {
			display: flex;
			gap: 16px;
			margin: 0 40px 32px 40px;
			.black {
				@include color("black");
				font-weight: 600;
			}
		}

		.post {
			margin-bottom: 32px;
			.postForm {
				display: flex;
				flex-direction: column;
				width: 100%;
				position: relative;
				overflow: hidden;
				gap: 8px;

				.container {
					background: #fff;
					@include boxShadow($alpha: 0.04);
					@include radius($radius: 16px);
				}
				> div {
					padding: 0 40px;
				}
				.editorHeader {
					display: flex;
					justify-content: space-between;

					.headerTitleContainer {
						display: flex;
						align-items: center;
						width: 100%;
						gap: 8px;

						.headerTitle {
							padding: 8px;
							font-weight: 600;
						}
					}
					.buttons {
						display: flex;
						align-items: center;
						gap: 8px;

						> div {
							margin: 0;
						}
					}
					.editModeButton {
						> button {
							padding: 11px 16px;
							height: 32px;
						}
					}
					.editModeButton > button,
					.selectCategoryBtn > button {
						@include radius($radius: 16px);
					}

					.selectCategoryBtn > button {
						@include border($color: "grey-400");
						display: flex;
						justify-content: space-between;
						padding: 11px 8px 11px 16px;
						height: 32px;
					}

					.text {
						font-size: 22px;
						font-weight: 700;
					}
				}

				.editor {
				}
			}

			.editorTopToolbar {
				.toggleNetwork {
					width: fit-content;
				}

				.shuffledCommentsNav {
					display: flex;
					align-items: center;
					gap: 16px;

					.navigator {
						display: flex;
						align-items: center;
						gap: 8px;
						padding: 8px;
						@include background("grey-200");
						@include radius($radius: 12px);
						.navPrev,
						.navNext {
							cursor: pointer;
							svg {
								transform: rotate(-90deg);
							}
						}
					}
				}
			}

			.editorBottomToolbar {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 8px;

				> div {
					display: flex;
					align-items: center;
					gap: 8px;
				}

				* > div {
					margin: 0;
				}

				.expiration {
					display: flex;
					gap: 8px;
					align-items: center;
					color: #2e394a;
					cursor: pointer;
					padding: 0 6px;
					&:hover {
						@include color("grey-600", $svg: true);
					}
				}
			}

			.editorCaptionToolbar {
				display: flex;
				align-items: center;
				gap: 24px;

				* > button {
					padding: 4px 0;
					height: 18px;
					&:hover {
						background: unset !important;
					}
				}

				.buttonsSeparator {
					height: 18px;
				}
			}

			.editorMediaToolbar {
				display: flex;
				align-items: center;
				gap: 16px;
				flex-wrap: wrap;
				.addShuffleImage {
					width: 96px;
					height: 72px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 8px;
					cursor: pointer;

					@include border($color: "grey-400", $style: dashed, $width: 1px);
					@include radius($radius: 4px);
				}
			}

			.emv {
				display: flex;
				align-items: center;
				gap: 8px;
				color: #818b9a;
				font-size: 14px;
				font-weight: 700;
				margin: 0 8px;
				text-decoration: underline;
				* > svg {
					fill: #818b9a;
				}
			}
		}

		.collapsedPost {
			display: flex;
			padding: 16px 24px 16px 20px;
			margin: 0 40px 8px 40px;
			gap: 8px;
			align-items: center;
			background: #fff;
			@include boxShadow($alpha: 0.04);
			@include border($radius: 15px, $color: "grey-400");

			.media {
				img,
				video {
					width: 80px;
					height: 58px;
					object-fit: cover;
					@include radius($radius: 4px);
				}
			}

			.text {
				display: flex;
				flex-direction: column;
				overflow: hidden;
				padding-left: 8px;
				flex: 1;

				.title {
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					-webkit-line-clamp: 1;
				}

				.comment {
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					white-space: pre-wrap;
				}
			}
		}

		.hasError {
			@include border($color: "red");
		}

		.category {
			display: flex;
			justify-content: space-between;

			> div {
				display: flex;
				gap: 8px;
			}
		}

		.collapseBtn {
			cursor: pointer;
		}

		.dropZone {
			height: 16px;
			margin: 0 40px;
		}

		.over {
			height: 16px;
			&.top {
				@include border($color: "grey-400", $where: "top");
			}
			&.bottom {
				@include border($color: "grey-400", $where: "bottom");
			}
		}

		.categoryList {
			min-height: 1px;
			.categoryItem {
				&.dragging {
					background: rgba(250, 251, 252, 0.7);

					.separator {
						display: none;
					}
				}

				.dragPlaceholder {
					div {
						//display: none;
					}
				}
			}
		}

		.postList {
			min-height: 1px;
			.dragPlaceholder {
				div {
					background-color: white;
					@include radius($radius: 15px);
					@include border($color: "grey-400", $style: dashed, $width: 1px);
				}
			}

			&.draggingOver {
				.separator {
					display: none;
				}
			}
		}

		.categoryContainer {
			display: flex;
			flex-direction: column;
			.categoryHeader {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 4px;
				padding: 0 40px;
				margin-bottom: 32px;

				.categoryTitleCt {
					display: flex;
					align-items: center;
					gap: 8px;

					.title {
						font-size: 22px;
						font-weight: 700;
						line-height: 1;
					}
				}
				.disabled {
					cursor: default;
					@include svgPathColor("grey-400");
				}

				.closeIcon,
				.collapseBtn {
					cursor: pointer;
					visibility: hidden;

					&:hover {
						@include svgPathColor("grey-600");
					}
				}

				&:hover {
					.closeIcon,
					.collapseBtn {
						visibility: visible;
					}
				}
			}
		}

		.postsToolbar {
			display: flex;
			padding: 0 40px;
			align-items: center;
			gap: 8px;
		}

		.separator {
			@include border($where: "top");
			margin: 0 40px 32px 40px;
		}
	}

	.formBottomToolbar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 24px 40px;
		@include border($color: "grey-200", $where: top);
		position: sticky;
		bottom: 0;
		background: #fff;

		> div {
			display: flex;
			align-items: center;
			gap: 16px;

			> div {
				margin: 0;
			}
		}
	}

	.draggable {
		cursor: move;

		&.disabled {
			cursor: default;
			@include svgPathColor("grey-400");
		}
	}

	.buttonsSeparator {
		@include border($where: "left");
		height: 24px;
	}
}

.autoLikeModal {
	background: #fff;
	padding: 16px 24px;
	@include radius($radius: 15px);
	@include background("white");
	box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 0.25);
	border-radius: 12px;
	z-index: map.get($zIndices, "modal-container");
	height: auto;

	.content {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.selectNetworks {
		@include border($color: "grey-400", $where: bottom);
		.networkOption {
			display: flex;
			align-items: center;
			gap: 8px;
			> div:nth-child(2) {
				flex: 1;
			}
		}
		.switch {
			display: flex;
			justify-content: space-between;
		}
	}

	.autoLikeLink {
		display: flex;
		justify-content: center;
	}
}

.droppableList {
	display: flex;
	flex-direction: column;
	position: relative;
}

.draggableItem {
	user-select: none;
}

.dragPlaceholder {
	display: flex;
	padding: 4px 40px;
	position: absolute;

	div {
		flex: 1;
	}
}
.editorTopToolbar {
	.toggleNetwork {
		width: fit-content;
	}

	.shuffledCommentsNav {
		display: flex;
		align-items: center;
		gap: 16px;

		.navigator {
			display: flex;
			align-items: center;
			gap: 8px;
			padding: 8px;
			@include background("grey-200");
			@include radius($radius: 12px);
			.navPrev,
			.navNext {
				cursor: pointer;
				svg {
					transform: rotate(-90deg);
				}
			}
		}
	}
}

.collectionScheduleModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px 40px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 24px;

	.content {
		width: 500px;
		height: 100%;
		max-height: 700px;
		min-height: 200px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding-bottom: 32px;
		@include border($color: "grey-300", $where: "bottom");

		.scheduleButton {
			width: fit-content;
			@include border($color: "grey-400");
			border-radius: 13px !important;
			margin: 7px;
			button {
				height: 24px;
				padding: 4px 16px 4px 8px;
			}
		}
	}

	.sendIcon {
		@include svgPathColor("pink-primary");
		margin-left: 5px;
	}

	.footer {
		display: flex;
		justify-content: flex-end;
		gap: 16px;
		align-items: center;

		> div {
			margin: 0;
		}
	}
}
