@import "../../global.scss";

.container {
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 24px;

	.emptyContainer {
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: center;
	}

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 10px;

		.headerRow {
			display: flex;
			flex-wrap: wrap;
			row-gap: 8px;
		}

		@include phone {
			flex-direction: column;
		}
	}

	.collectionHeader {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		@include background;

		.info {
			display: flex;
			gap: 24px;
			flex: 1;
			align-items: center;

			&.postInfo {
				padding-right: 16px;
				@include border($where: "right");
			}

			.collectionHeaderImages {
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				flex: 1;
				gap: 1px;
				height: 140px;
				align-items: center;
				justify-content: center;

				&.post {
					display: flex;
					height: unset;
					flex: unset;
					margin-right: 0;
					gap: 16px;
					width: 160px;

					> :first-child {
						@include radius;
						height: 84px;
					}
				}

				> :first-child {
					@include radius;
				}

				> * {
					justify-content: center;
					height: 139px;
					width: 100%;
				}

				.image {
					object-fit: cover;
				}

				.video {
					object-fit: cover;
				}

				.text {
					display: flex;
					flex-direction: column;
					@include background("grey-600");
					align-items: center;
					height: 100%;
				}
			}

			.collectionText {
				display: flex;
				flex-direction: column;
				flex: 1;

				.title {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.titleText {
						max-width: 200px;
					}
				}
			}

			@include phone {
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}

		.analytics {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			&.applyBorder {
				@include border($where: "right", $color: "grey-200");
			}

			.row {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;

				.emvContainer {
					display: flex;
					align-items: center;
				}
			}
		}

		@include tablet-and-phone {
			flex-direction: column;
			text-align: center;
		}
	}

	.postsContainer {
		display: flex;
		flex: 1;
		flex-direction: column;
		margin-right: 24px;
		gap: 16px;

		.postHeader {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.opens {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 8px;
			}
		}

		.category {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}

	.clickable {
		cursor: pointer;
	}
}

.statsModal {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	flex: 1;
	padding-bottom: 16px;

	.tabs {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
	}

	.userList {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 8px;

		.userItem {
			padding-bottom: 8px;
			gap: 7px;
			display: flex;
			flex: 1;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
		}
		.separator {
			margin: 0px;
		}
	}
}
