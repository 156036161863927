@import "../../global.scss";

.container {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.header {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;

		&.grouped {
			justify-content: flex-start;
			gap: 20px;
		}

		.title {
			display: flex;
			align-items: center;
		}

		@include phone {
			flex-direction: column;
		}
	}

	.userCard {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		grid-gap: 25px;

		.userInfo {
			display: flex;

			.avatar {
				display: flex;
				position: relative;
				margin-right: 24px;

				.hint {
					position: absolute;
					bottom: 0;
					right: -10px;
					@include color("blue-100", $background: true);
					@include radius($radius: 50%);
					height: 40px;
					width: 40px;
					display: flex;
					align-items: center;
					padding: 6px 4px 0;

					.text {
						text-align: center;
					}
				}
			}

			.name {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.progress {
					height: 10px;
					width: 208px;
					@include color("grey-300", $background: true);
					@include radius($radius: 100px);
					display: flex;
					align-items: center;

					.progressFill {
						margin-left: 4px;
						margin-right: 4px;
						@include color("blue-primary", $background: true);
						border-radius: 99px 0 99px 99px;
						height: 5px;
					}
				}
			}
		}

		.badgeList {
			display: flex;
			grid-gap: 8px;
			flex-direction: column;
			align-items: flex-end;

			.badges {
				display: flex;
				gap: 16px;
			}
		}

		@include tablet-and-phone {
			flex-direction: column;
		}
	}

	.engagementPostCards {
		& > div {
			overflow: hidden;
		}
		.chartHeader {
			margin-bottom: 26px;
		}

		.chartContainer {
			display: flex;
			align-items: center;
			flex-direction: column;
			gap: 15px;
		}

		@include tablet-and-phone {
			grid-template-columns: 1fr;
		}
	}

	.sharesTabs {
		background: none;
		& > div[class*="toggle_selected"] {
			border-bottom: 1px solid #d32b79;
			border-radius: unset;
			font-weight: 700;
		}
		& > div[class*="toggle_toggle"] {
			& > div[class*="image_icon"] {
				display: none;
			}
		}
	}

	.buttonsContainer {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		row-gap: 16px;
		.buttonsGroup {
			display: flex;
			column-gap: 16px;
			justify-content: flex-end;
			flex-wrap: wrap;
			align-items: center;
			> div {
				margin-top: 0;
			}
		}
	}
	.pageControl {
		display: flex;
		column-gap: 16px;

		> * {
			white-space: nowrap;
		}
	}

	.topPost {
		display: flex;
		align-items: center;
		padding: 16px;
		grid-gap: 28px;

		.postContent {
			display: flex;
			gap: 28px;
			align-items: center;
			flex: 1;
		}
		.postStats {
			display: flex;
			gap: 28px;
			align-items: center;
			width: 21%;
		}

		.openGraphMedia {
			width: 100px;
		}

		.text {
			display: flex;
			flex-direction: column;
			gap: 2px;
			padding: 8px 0;
		}

		.stat {
			display: flex;
			flex-direction: column;
			align-items: center;

			> span {
				white-space: nowrap;
			}
		}

		@include tablet {
			flex-direction: column;
			text-align: center;
			height: auto;
		}

		@include phone {
			flex-direction: column;
			text-align: center;
			.postContent,
			.postStats {
				flex-direction: column;
				text-align: center;
			}
		}
	}
}

.badgeRow {
	display: grid;
	grid-row-gap: 16px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

	img {
		width: 46px;
	}
}

.faded {
	opacity: 0.35;
}
