@use "sass:map";
@import "../../global.scss";
.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
	clear: unset;
	content: unset;
	display: unset;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button,
.ql-snow .ql-picker {
	float: unset;
}
.ql-container {
	font-family: unset;
	.ql-editor.ql-blank:before {
		font-style: unset;
		@include color("grey-600");
	}
}
.ql-snow.ql-toolbar,
.ql-snow .ql-toolbar {
	display: flex;

	.space {
		width: 20px;
	}
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
	@include color("blue-primary");
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
	fill: map.get($colors, "blue-primary");
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
	stroke: map.get($colors, "blue-primary");
}
@media (pointer: coarse) {
	.ql-snow.ql-toolbar button:hover:not(.ql-active),
	.ql-snow .ql-toolbar button:hover:not(.ql-active) {
		@include color("black");
	}
	.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
	.ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
	.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
	.ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
		stroke: map.get($colors, "black");
	}
	.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
	.ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
	.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
	.ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
		stroke: map.get($colors, "black");
	}
}
.ql-snow .ql-stroke {
	stroke: map.get($colors, "black");
}
.ql-snow .ql-stroke-miter {
	stroke: map.get($colors, "black");
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
	fill: map.get($colors, "black");
}
.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
	@include background("grey-100");
}
.ql-snow .ql-editor pre.ql-syntax {
	@include color("grey-100", $background: "black");
}
.ql-snow .ql-picker {
	@include color("black");
}
.ql-snow .ql-picker-options {
	@include background("white");
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
	@include color("grey-400");
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
	fill: map.get($colors, "grey-400");
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
	stroke: map.get($colors, "grey-400");
}
.ql-snow .ql-color-picker.ql-background .ql-picker-item {
	@include background("white");
}
.ql-snow .ql-color-picker.ql-color .ql-picker-item {
	@include background("black-dark");
}
.ql-toolbar.ql-snow {
	@include border($color: "grey-400");
	font-family: unset;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
	border-color: map.get($colors, "grey-400");
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	border-color: map.get($colors, "grey-400");
}
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
	border-color: map.get($colors, "black-dark");
}
.ql-snow .ql-tooltip {
	@include color("black", $background: "white");
	@include border($color: "grey-400");
}
.ql-snow .ql-tooltip input[type="text"] {
	@include border($color: "grey-400");
}
.ql-snow .ql-tooltip a.ql-action::after {
	@include border($color: "grey-400", $where: "right");
}
.ql-snow a {
	@include color("blue-primary");
}
.ql-container.ql-snow {
	border: none;
}
