@use "sass:map";
@import "../../global.scss";

.menuItem {
	display: flex;
	gap: 8px;
	height: 40px;
	align-items: center;
	padding: 0 16px;
	cursor: pointer;
	&:hover {
		@include background("grey-200");
	}
	position: relative;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.fileUpload {
	color: red;
	font-size: 16px;
}

.inputFile {
	display: none;
}
.canvaMediaModal {
	gap: 24px;
	.canvaModalTitle {
		padding: 24px;
		padding-bottom: 0;
	}
}

.menu {
	overflow-y: auto;
	@include background;
	border-radius: 12px;
	min-width: 100px;
	@include boxShadow;
	z-index: map.get($zIndices, "modal-container");
	padding: 8px 0;

	// .disabled {
	// 	@include color("grey-400", $svg: true);
	// 	cursor: default;
	// 	pointer-events: none;
	// }
}

.canvaMediaHeader {
	display: flex;
	gap: 8px;
}
