@import "../../global.scss";
.container {
	display: flex;
	flex-direction: column;
	.referralCollapsibleButton {
		padding: 0;
		box-shadow: none;
	}
	ul {
		margin-bottom: 16px;
		margin-top: 2px;
	}
}
