@use "sass:map";
@import "../../global.scss";

.calendar {
	.header {
		flex-wrap: wrap;
		row-gap: 8px;

		.headerRow {
			flex-wrap: wrap;
			gap: 8px 16px;
		}
	}
}
.sticky {
	top: 0;
	position: sticky;
	@include background("grey-100");
	padding-bottom: 16px;
	z-index: 2;

	&::before {
		z-index: -1;
		content: "";
		inset: -24px -24px 0 -24px;
		position: absolute;
		@include background("grey-100");
		@include phone {
			inset: -20px -20px 0 -20px;
		}
	}
}

.monthlyCalendar {
	@include border($color: "grey-400");
	padding: 0;
	position: relative;

	.header {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(7, minmax(0, 1fr));
		text-align: center;
	}

	.days {
		border: none;
		display: grid;
		grid-template-columns: repeat(7, minmax(0, 1fr));

		.day {
			padding: 5px;
			height: 180px;
			display: flex;
			flex-direction: column;
			@include border($where: "bottom", $color: "grey-400");

			.events {
				position: relative;
				flex: 1;
				display: flex;
				flex-direction: column;
				margin-top: 5px;
				.over {
					@include radius;
					height: 72px;
					margin-bottom: 16px;
					border: 1px dashed;
				}
				.item {
					justify-content: center;
					flex: 1;
					margin-top: 2px;
					&:only-child {
						height: 135px;
					}
				}
			}

			&:not(:nth-child(7n + 1)) {
				@include border($where: "left", $color: "grey-400");
			}

			&:nth-last-child(-n + 7) {
				border-bottom: none;
			}

			&.notCurrent {
				@include color("grey-600");
			}
		}

		.dateText {
			display: flex;
			justify-content: space-between;
			padding: 0 8px;
			min-height: 30px;

			.todaysDay {
				@include background("pink-primary");
				@include color("white");
				@include radius(50%);
				height: 30px;
				line-height: 30px;
				text-align: center;
				width: 30px;
			}
		}
	}
}

.weeklyCalendar {
	@include border($color: "grey-400");
	padding: 0 0 0 16px;
	position: relative;
	z-index: 0;

	.weeklyView {
		display: flex;
		flex-direction: row;

		.hours {
			border: none;
			position: relative;
			top: 80px;
			@include color("grey-500");

			.over {
				@include radius;
				height: 72px;
				margin-bottom: 16px;
				border: 1px dashed;
			}

			&:first-child {
				margin-right: 16px;
			}

			& > div {
				height: 80px;
			}

			.item {
				height: 80px;
				&:first-child:not(:last-child) {
					width: 60%;
				}
				&:last-child:not(:first-child) {
					left: 40%;
					width: 60%;
				}
			}
		}
	}

	.days {
		display: flex;
		flex-direction: row;
		flex-grow: 1;

		.header {
			padding: 19px 8px;
			text-transform: uppercase;
			@include color("grey-500");
			@include border($where: [ "bottom" ], $color: "grey-400");

			h1 {
				@include color("black");
			}
		}

		.today {
			@include color("pink-primary");

			h1 {
				@include color("pink-primary");
			}
		}

		.hours {
			top: 0;

			.hour {
				@include border($where: "bottom", $color: "grey-400");
				position: relative;

				.item {
					position: absolute;
					z-index: 1;
				}

				.more {
					bottom: 0;
					position: absolute;
					right: 0;
				}
			}
		}

		& > div {
			min-width: calc(100% / 7);

			&:not(:last-child) {
				@include border($where: [ "right" ], $color: "grey-400");
			}
		}
	}
}

.dailyCalendar {
	@include border($color: "grey-400");
	padding: 10px 16px;
	position: relative;

	.header {
		@include color("grey-500");
		padding: 0 60px;
		text-transform: uppercase;

		h1 {
			@include color("black");
		}
	}

	.timeRange {
		position: relative;
		align-items: center;
		display: flex;
		height: 80px;

		.line {
			@include border($where: [ "top" ], $color: "grey-300");
			flex: 1;
			height: 1px;
			margin-left: 15px;

			.over {
				@include radius;
				height: 72px;
				margin-bottom: 16px;
				border: 1px dashed;
			}
		}
	}

	.events {
		display: flex;

		.item {
			position: relative;
			height: 80px;
			width: 100%;
			z-index: 1;
			.more {
				bottom: 0;
				position: absolute;
				right: 0;
			}
		}
	}
}

.dateViews {
	margin: 0 14px;
}

.buttonMenu {
	@include background;
	@include boxShadow;
	border-radius: 12px;
	min-width: 277px;
	overflow-y: auto;
	padding: 16px;
	width: 331px;
	z-index: map.get($zIndices, "modal-container");

	.divider {
		@include border($where: "bottom");
		padding-bottom: 8px;
	}

	.item {
		margin-top: 8px;
	}
}

.information {
	display: flex;
	gap: 5px;
}

.item {
	@include radius(8px);
	@include border($color: "grey-300");
	@include color("black");
	font-size: 12px;
	padding: 5px 8px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&.collection {
		@include background("pink-100");
	}
	&.post {
		@include background("blue-100");
	}

	.draggable {
		display: flex;
		justify-content: flex-end;
		cursor: move;
	}
}

.dot {
	margin: 0 4px;
}
