@import "../../../global.scss";

.header {
	gap: 24px;

	.text > * {
		display: inline;
	}
}

.topPost {
	display: flex;
	align-items: center;
	padding: 16px;
	grid-gap: 28px;

	.postContent {
		display: flex;
		gap: 28px;
		align-items: center;
		flex: 1;
	}
	.postStats {
		display: flex;
		gap: 28px;
		align-items: center;
	}

	.openGraphMedia {
		width: 100px;
	}

	.text {
		display: flex;
		flex-direction: column;
		gap: 2px;
		padding: 8px 0;
	}

	.stat {
		display: flex;
		flex-direction: column;
		align-items: center;

		> span {
			white-space: nowrap;
		}
	}

	@include tablet {
		flex-direction: column;
		text-align: center;
		height: auto;
	}

	@include phone {
		flex-direction: column;
		text-align: center;
		.postContent,
		.postStats {
			flex-direction: column;
			text-align: center;
		}
	}
}
