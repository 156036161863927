@use "sass:map";
@import "../global.scss";

.container {
	@include fill-absolute;
	display: flex;
	align-items: center;
	flex-direction: column;
	pointer-events: none;
}

.toast {
	@include background;
	@include radius;
	pointer-events: auto;
	display: grid;
	gap: 32px;
	grid-template-columns: 1fr 24px;
	padding: 18px;
	margin: 8px;
	align-items: center;
	max-width: 100%;
	z-index: map.get($zIndices, "toast-container");
	opacity: 1;
	transition: opacity 1s linear;

	.text {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	&.hidden {
		opacity: 0;
	}

	@include desktop {
		min-width: 400px;
	}

	@each $color in [ "blue", "green", "red" ] {
		$actualColor: if($color == "blue", "blue-primary", $color);
		&.#{$color} {
			@include border($color: $actualColor, $width: 2px);
			@include svgPathColor($actualColor);
		}
	}

	h5 {
		padding-right: 32px;
		line-height: 24px;
	}

	.close {
		@include focus;
		@include svgPathColor("black");
		@include radius(6px);

		&:hover {
			@include background("blue-100");
		}
	}
}
