@import "../../global.scss";

.description {
	display: block;
	max-width: 800px;
}

.inputs {
	max-width: 800px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.textarea {
	textarea {
		// max-height: 300px;
		overflow-y: auto !important;
	}
}
.fieldset {
	@include border("grey-300");
	margin: 20px 0;
	legend {
		font-size: 20px;
		@include color("grey-600");
		margin-bottom: 20px;
		padding: 0 5px;
	}
}
.select {
	flex-direction: column;
	align-items: flex-start;
	margin-top: 16px;
	margin-bottom: 16px;
	label {
		margin-bottom: -10px;
	}
}

.box {
	box-shadow: 0px 2px 10px 0px rgba(46, 57, 74, 0.04);
	background-color: white;
	padding: 32px;
	border-radius: 15px;
	@include border("grey-300");
}
