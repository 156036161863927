@import "../../global.scss";

.container {
	@include background("grey-200");
	@include radius;
	@include svgPathColor("grey-500");
	display: inline-flex;
	align-items: center;
	padding: 8px;

	.toggle {
		@include radius(7.5px);
		@include color($color: "grey-600");
		padding: 4px 18px;
		display: flex;
		align-items: center;
		cursor: pointer;
		gap: 8px;

		&:focus-visible {
			outline-offset: -2px;
		}

		&.selected {
			@include svgPathColor("pink-primary");
			@include color($color: "black", $background: "white");
			box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
			cursor: default;

			&.icon {
				@include svgPathColor("blue-primary");
			}
		}

		&.compact {
			padding: 1.5px 8px;
		}

		&.disabled {
			cursor: not-allowed;
			@include color("grey-600", $background: "grey-200", $borderColor: "grey-500");
		}

		> span {
			@include noSelection();
		}
	}
}
