@use "sass:map";
@import "../../../../global.scss";

.postForm {
	display: flex;
	flex-direction: column;
	min-width: 450px;
	position: relative;
	background: #fff;
	overflow: hidden;
	max-height: calc(100% - 50px);
	@include radius($radius: 16px);
	@include boxShadow($alpha: 0.04);

	&.editMode {
		max-height: 100%;
	}

	.topContainer {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 40px 40px 32px 40px;

		.shareTo {
		}

		.sharingTime {
			> button {
				padding: 11px 8px;
				height: 32px;
				border-color: map.get($colors, "grey-400") !important;
				@include radius($radius: 16px);
				box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
			}
		}
	}
	.recipient {
		display: flex;
		align-items: center;
		gap: 16px;
		// padding: 0px 40px;
		justify-content: space-between;
		min-height: 40px;

		.dropdown {
			display: flex;
			align-items: center;
			flex: 1;
			gap: 8px;

			* > div {
				display: flex;
				flex: 1;
			}

			&.noValue {
				button {
					@include color($color: "grey-600");
				}
			}
			button {
				position: relative;
				flex: 1;
				padding-left: 0;

				> div {
					position: absolute;
					right: 0;
				}
			}
			> div {
				display: flex;
				flex: 1;
				@include border($where: "bottom", $color: "grey-300", $width: 1px);
			}
		}

		.networks {
			display: flex;
			align-items: center;
			column-gap: 8px;

			.network {
				padding: 7px;
				@include radius($radius: 8px);
				@include border($color: "grey-300", $width: 1px);
				cursor: pointer;
				&.selected {
					border-color: #0997d9;
					background: #daf2fc;
				}
			}
		}
	}

	.toggleNetwork {
		width: fit-content;
		padding: 6px;
		@include radius($radius: 12px);

		> div {
			@include radius($radius: 8px);
		}
	}

	.editorHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		gap: 8px;
		padding: 4px 0;
		.network {
			display: flex;
			gap: 8px;
			padding: 2px 0 3px 0;
		}

		.editModeButton {
			> button {
				padding: 11px 16px;
				height: 32px;
				@include radius($radius: 16px);
			}
		}
	}

	.postBody {
		padding: 24px 40px 40px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		background: #fafbfc;
		overflow: hidden auto;
		@include border($where: "bottom", $width: 1px, $color: "grey-300");
		@include border($where: "top", $width: 1px, $color: "grey-300");

		.postStats {
			display: flex;
			padding: 0 32px;
			.emv {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;
				color: #818b9a;
				font-size: 14px;
				font-weight: 700;
				text-decoration: underline;
				fill: #818b9a;
			}
		}

		.editorBottomToolbar {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.buttonsContainer {
				display: flex;
				align-items: center;
				gap: 8px;
				> div {
					margin: 0;
				}
			}

			.schedulerContainer {
				display: flex;
				align-items: center;
				cursor: pointer;

				> div {
					margin: 0;
				}
			}
		}

		.sharesGroup {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.groupHeader {
				> div {
					display: flex;
					justify-content: space-between;
					width: 100%;
				}

				.titleContainer {
					display: flex;
					flex-direction: column;

					.title {
						font-size: 22px;
						font-weight: 700;
					}
				}

				.socialScoreContainer {
					display: flex;
					justify-content: flex-end;
					align-items: end;
				}

				.socialScoreSuggestion {
					justify-content: flex-end;
					font-size: 10px;
					display: flex;
					flex: 1;
					cursor: default;
				}
			}
		}
	}

	.formBottomToolbar {
		position: sticky;
		bottom: 0;
		padding: 32px 40px;
		background: #fff;
		display: flex;
		justify-content: right;

		.buttonsContainer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 1;
			gap: 16px;
			> div {
				margin: 0;
			}
		}
	}

	.buttonsSeparator {
		@include border($where: "left");
		height: 24px;
	}

	.separator {
		@include border($where: "top");
		margin: 16px 0;
	}
}
