@import "../../global.scss";

.container {
	@include fix-min;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 24px;
	row-gap: 8px;

	& + & {
		margin-top: 0;
	}

	.status {
		@include background("grey-300");
		@include border($radius: true, $width: 0);
		align-self: center;
		padding: 4px 16px;

		&.approved {
			@include background("blue-100");
			@include color("blue-primary");
		}
	}

	.articleContainer {
		display: flex;
		@include border($radius: true);
		height: 120px;

		> .video,
		img {
			width: 100%;
			object-fit: cover;
			border-radius: 14px;
		}
	}
}

.shareEvent {
	display: flex;
	align-items: center;
	gap: 8px;

	&.shareEvent > span {
		line-height: 100%;
	}
}
