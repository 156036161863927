@import "../../global.scss";

.card {
	@include click;
	@include radius;
	@include background;
	@include boxShadow;
	padding: 32px 40px;

	@include phone {
		padding: 16px 20px;
	}

	.column {
		display: flex;
		flex-direction: column;
	}
}

.borderLeft {
	@include border($where: "left");
}

.header {
	display: flex;
	align-items: center;

	.headerContent {
		flex-grow: 1;
	}

	.icons {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding-left: 16px;
		margin-left: 16px;
		min-height: 39px;

		> * + * {
			margin-left: 12px;
		}
	}
}

.divider {
	margin: 32px 0 24px 0;
}

.collapsible {
	padding: 0;
	@include boxShadow;
	@include border;

	.header {
		@include background;
		@include radius($where: "top");
		padding: 8px 58px 8px 24px;
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;

		.arrow {
			position: absolute;
			right: 24px;
			&.disabled {
				@include color("grey-200", $svg: true);
			}
		}
	}

	&.hidden .header {
		@include radius;
	}

	.body {
		padding: 16px 24px;
	}
}
