@import "../../../global.scss";

.popularPostsModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px 0;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;

	> :first-child {
		padding: 0 16px;
	}

	> :nth-child(2) {
		overflow: auto hidden;
	}

	.content {
		width: 1280px;
		height: 100%;
		max-height: 700px;
		min-height: 500px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;

		.toolbar {
			padding: 0 16px;
		}

		.postsContainer {
			display: flex;
			overflow: hidden;
		}

		&.comparisonMode {
			width: 1280px;
			.postsList {
				.listHeader {
					margin-bottom: 8px;
					display: block;
				}
				.post {
					flex-direction: column;
					padding: 8px 16px;
					gap: 8px;
					.infoContainer {
						width: unset;
					}
					.text {
						width: unset;

						.title {
							-webkit-line-clamp: 1;
						}
						.description {
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							white-space: pre-wrap;
						}
					}
					.image {
						height: 50px;
						width: 64px;
						img,
						video {
							height: 50px;
							width: 64px;
						}
					}
					.statsContainer {
						padding: 0;
						gap: 8px;

						> :first-child {
							padding: 15px 28px;
							@include border($color: "grey-300", $where: "right");
						}
						.postStats {
							flex-direction: column;
							gap: 8px;
							justify-content: center;
							width: 72px;
							:first-child {
								text-align: center;
								min-width: 48px;
							}
							> * {
								line-height: 130%;
							}
						}
					}
				}
			}
		}
		.postsList {
			padding: 0 16px;
			overflow: hidden auto;
			flex: 1;
			.post {
				display: flex;
				align-items: flex-start;
				padding: 8px 16px;
				justify-content: space-between;
				@include boxShadow($alpha: 0.14);
				@include radius;
				gap: 24px;
				height: max-content;
				margin-bottom: 8px;
				@include tablet-and-phone {
					flex-direction: column;
				}

				&:nth-child(odd) {
					@include background("grey-100");
				}

				.infoContainer {
					display: flex;
					gap: 24px;
					width: 55%;
				}
				.statsContainer {
					display: flex;
					gap: 24px;
					padding: 0 8px;
					flex: 1;
					justify-content: space-between;
					align-items: center;

					.postStats {
						display: flex;
						flex-direction: column;
						text-align: center;
					}
				}

				.image {
					height: 50px;
					width: 64px;
					img,
					video {
						height: 50px;
						width: 64px;
						@include radius;
						object-fit: cover;
					}
				}
				.text {
					height: 100%;
					line-height: 100% !important;

					.titleCt {
						display: flex;
						gap: 8px;
						align-items: center;
					}
					.title {
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						white-space: pre-wrap;
						word-break: break-all;
					}

					.description {
						text-overflow: unset;
						white-space: pre-wrap;
						display: block;
						word-break: break-all;
					}
				}

				@include phone {
					flex-direction: column;
					text-align: center;
				}
			}
		}
	}

	.loader {
		display: flex;
		justify-content: center;
	}
}
