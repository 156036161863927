@use "sass:map";
@import "../../../global.scss";

.container {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 24px;
	.largeSpace {
		margin-top: 32px;
		margin-bottom: 32px;
	}
	.noSpace {
		margin-top: 0;
		margin-bottom: 0;
	}
	.blue {
		@include color("blue-primary", $borderColor: true);
	}
	.emailContainer {
		display: flex;
		gap: 64px;
		margin: 0 auto;

		.leftContainer {
			width: 296px;
			height: fit-content;
			position: sticky;
			top: 0;
			.logoContainer {
				display: flex;
				margin-bottom: 32px;
				flex-direction: column;
				gap: 16px;
				.logoDropZone {
					&.active {
						@include background("grey-200");
					}
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					padding-bottom: 16px;
					padding-top: 16px;
					gap: 16px;
					align-items: center;
					@include border($radius: 8px, $style: dashed, $color: "grey-500", $width: 1px);
					height: 152px;
					img {
						max-width: 90%;
						max-height: 70%;
						outline: none;
					}
					.newImage {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: auto 0;
						.or {
							margin-top: 8px;
							margin-bottom: -8px;
							position: relative;
							padding: 0 5px;
							&::before {
								content: "";
								display: block;
								position: absolute;
								left: -105px;
								top: 50%;
								width: 105px;
								height: 1px;
								background-color: #d9d9d9;
							}
							&::after {
								content: "";
								display: block;
								position: absolute;
								left: 100%;
								top: 50%;
								width: 105px;
								height: 1px;
								background-color: #d9d9d9;
							}
						}
					}
				}
				.sliderContainer {
					display: flex;

					gap: 8px;
					.slider {
						flex: 1;
						display: flex;
						position: relative;
						.leftPart {
							position: absolute;
							left: 0;
							@include background("blue-primary");
							height: 4px;
							top: 6px;
							border-radius: 5px;
							pointer-events: none;
						}
						input {
							width: 100%;
							-webkit-appearance: none;
							appearance: none;
							height: 4px;
							@include background("grey-300");
							margin: 6px 0;
							border-radius: 5px;
							&::-webkit-slider-thumb {
								-webkit-appearance: none;
								appearance: none;
								width: 16px;
								height: 16px;
								border-radius: 50%;
								background: white;
								cursor: pointer;
								@include border("grey-400");
								opacity: 0;
							}
						}
						.bullet {
							width: 16px;
							height: 16px;
							border-radius: 50%;
							pointer-events: none;
							background: white;
							margin: 0;
							cursor: pointer;
							@include border("grey-400");
							position: absolute;
							// top: 1px;
						}
					}
					span {
						font-size: 40px;
					}
				}
			}
			.articleStyling {
				margin-top: 32px;
				display: flex;
				flex-direction: column;
				.inputContainer {
					position: relative;
					display: flex;
					align-items: center;
					input {
						width: 32px;
						height: 32px;
						border-radius: 50%;
						border: none;
						-webkit-appearance: none;
						appearance: none;
						position: relative;
					}
					label {
						margin-left: 16px;
					}
					.colorBox {
						position: absolute;
						pointer-events: none;
						top: 0;
						width: 32px;
						border-radius: 50%;
						height: 32px;
						border: 2px solid white;
						z-index: 1;
					}
				}
			}
		}
		.rightContainer {
			width: 580px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 24px;
			@include radius(15px);
			@include background("white");
			min-height: 500px;
			padding: 32px 48px;
			@include boxShadow(0.04);
			@include border("grey-300");
			.emailHeader {
				display: flex;
				align-items: center;
				flex-direction: column;
				gap: 8px;
				.by {
					display: flex;
					align-items: center;
					gap: 4.4px;
					p {
						font-size: 8px;
					}
				}
			}
			.shareAll {
				background-color: white;
				font-size: 16px;
				font-weight: 600;
				border: 1px solid;
				border-radius: 15px;
				padding: 8px 24px;
			}
			.shareButton {
				background-color: white;
				font-size: 12px;
				font-weight: 600;
				border: 1px solid;
				border-radius: 999px;
				padding: 4px 16px;
			}
			.authorMessage {
				display: flex;
				padding: 16px 24px;
				gap: 24px;
				background-color: #f7fcfe;
				border-radius: 2px;
				border: 0.65px solid #daf2fc;
				.message {
					display: flex;
					flex-direction: column;
					gap: 8px;
					p {
						font-size: 13px;
					}
				}
				.avatar {
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 8px;
					align-items: center;
					p {
						// width: 81px;
						text-wrap: nowrap;
						font-size: 11px;
					}
				}
			}
			.posts {
				.postDescription {
					font-size: 13px;
					font-style: italic;
				}
			}
			.alignCenter {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.engagement {
				display: flex;
				flex-direction: column;
				gap: 24px;
				width: 100%;
				align-items: center;
				background-color: #f7fcfe;
				border-radius: 2px;
				border: 0.65px solid #daf2fc;
				padding: 24px;
				.numbers {
					display: flex;
					gap: 24px;
					div {
						h3 {
							@include color("blue-primary");
						}
						width: 206px;
						gap: 8px;
						display: flex;
						align-items: center;
						flex-direction: column;
					}
				}
			}
			.fakeLink {
				text-decoration: underline;
			}
		}
		@include tablet-and-phone {
			flex-direction: column;
			.leftContainer {
				position: relative;
			}
		}
	}
}
.sticky {
	position: sticky;
	bottom: 0;
	padding-bottom: 16px;
	left: 0;
	right: 0;
	z-index: 1;
	@include background("grey-100");
	@include border($where: "top", $color: "grey-300");
	padding-top: 24px;
}
