@use "sass:map";
@import "../../global.scss";

.slider {
	@include background($color: "black-dark");
	position: relative;
	width: 100%;
	height: 100%;
	user-select: none;

	.slideImage {
		display: none;
		width: 100%;
		height: 100%;
		align-items: center;
		align-content: center;
		justify-content: center;

		&.active {
			display: flex;
		}

		img,
		video {
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}

	.sliderBtn {
		position: absolute;
		top: 50%;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		fill: #fff;
		@include background($color: "black", $alpha: 0.8);
		@include radius($radius: 50%);

		&.left {
			left: 20px;
			svg {
				transform: rotate(-90deg);
			}
		}
		&.right {
			right: 20px;
			svg {
				transform: rotate(90deg);
			}
		}
	}

	.slideIndicator {
		position: absolute;
		bottom: 40px;
		width: 100%;
		display: flex;
		justify-content: center;

		.dot {
			> div {
				width: 8px;
				height: 8px;
				@include background($color: "white", $alpha: 0.5);
				@include radius($radius: 50%);
			}

			&.active {
				> div {
					@include background($color: "white");
				}
			}

			width: 18px;
			height: 18px;
			padding: 5px;
			cursor: pointer;
		}
	}
}
