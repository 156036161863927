.canvaPage {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.canvaHeader {
	width: min(95%, 600px);
}
.canvaReturnContainer {
	background: white;
	margin-bottom: 16px;
	border-radius: 16px;
	padding: 40px 40px 32px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
	margin-top: 16px;
	// align-items: center;
	.loadingItem {
		display: flex;
		gap: 16px;
		align-items: center;
		.dot {
			width: 24px;
		}
	}
}
