@import "../../../global.scss";

.exportReportModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px 0;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.header {
		padding: 0 24px;
		display: flex;
		gap: 16px;
		align-items: center;
	}

	.content {
		width: 480px;
		max-height: 700px;
		display: flex;
		flex-direction: column;
		padding: 0 24px;

		.optionsCt {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding-bottom: 8px;
		}
		.toolbar {
			display: flex;
			justify-content: right;
			gap: 16px;
			padding-top: 8px;
			> * {
				margin: 0;
			}
		}
	}
}
