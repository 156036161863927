@use "sass:map";
@import "../../../global.scss";

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 8px;
}

.arrowsContainer {
	display: flex;
	align-items: center;

	.arrow {
		&:focus-within {
			@include outline;
		}

		&.disabled {
			@include svgPathColor("grey-400");
			cursor: not-allowed;
		}
	}
}
