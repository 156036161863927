@import "../../global.scss";

$panelWidth: 367px;

.container {
	display: grid;
	@include desktop {
		grid-template-columns: 1fr $panelWidth;
		grid-template-rows: 40px 1fr;
	}
	@include tablet-and-phone {
		position: relative;
	}
	gap: 16px;
	$container: &;
	transition: grid-template-columns 0.5s ease-in-out;
	width: 100%;
	max-height: 100%;

	.headerCt {
		flex-wrap: wrap;
		height: fit-content;
	}

	.headerRow {
		flex: 1;
		> div {
			flex-wrap: wrap;
			row-gap: 8px;
		}
	}

	&.closed {
		grid-template-columns: 1fr;
	}

	.child {
		overflow-y: auto;
		width: 100%;
	}

	.show {
		grid-column: 1 / -1;
		grid-row: 1;
		justify-self: end;
		@include atContext($container, ".hasHeader") {
			grid-column: 2 / -1;
		}
	}

	.panel {
		@include desktop {
			grid-row: 2;
			grid-column: 2;
			width: $panelWidth;
			max-height: max-content;
			overflow-y: auto;

			@include atContext($container, ".closed") {
				display: none;
			}
		}
		@include tablet-and-phone {
			background-color: white;
			@include boxShadow;
			@include radius;
			padding: 20px;
			position: absolute;
			z-index: 1;
			top: 50px;
			right: 0;
			left: 0;
			max-width: $panelWidth + 40px;
			margin-left: auto;
			@include desktop {
				display: none;
			}
		}
	}

	.hidden {
		display: none;
	}
}

@keyframes disappear {
	0% {
		display: initial;
	}
	100% {
		display: none;
	}
}
