@import "../../global.scss";

.container {
	display: flex;
	flex: 1;
	flex-direction: column;

	.accountsContainer {
		margin-top: 16px;
	}

	.profileHeader {
		display: flex;

		.profile {
			flex: 1 1;
			flex-direction: column;
			display: flex;
		}

		.corporateInfoContainer {
			gap: 16px;

			@include phone {
				flex-direction: column;
				align-items: center;
			}
		}

		.networkIcons {
			align-items: center;
			display: flex;
			flex: 1 1;
			justify-content: center;
			gap: 13px;

			@include phone {
				gap: 6px;
			}
		}

		@include tablet-and-phone {
			flex-direction: column;
			gap: 16px;
		}
	}
}

.corporateContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.corporateActions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		gap: 24px;

		> * {
			flex: 1;
		}

		@include phone {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.corporateNetworks {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 24px;

		.social {
			display: flex;
			gap: 24px;
			justify-content: space-between;

			.header {
				display: flex;
				flex-direction: column;
				flex: 1;

				.hourContainer {
					gap: 8px;
				}

				.hourPickerLabel {
					@include color("black");
				}
			}

			.actions {
				@include background("grey-100");
				display: flex;
				flex-direction: column;
				gap: 24px;
				flex: 2;
				padding: 24px;
			}

			.infoSocial {
				margin-top: 4px;

				.infoItalic {
					font-style: italic;
				}
			}

			@include tablet-and-phone {
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}

	.socialMediaHeader {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		gap: 6px;
	}
}

.connectPageContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
