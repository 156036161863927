@use "sass:map";
@import "../../global.scss";

.container {
	@include click;

	display: flex;
}

.icon {
	position: relative;
	@include svgPathColor("grey-300");
	&.activeIcon {
		@include svgPathColor("blue-primary");
	}
	&.medium svg {
		width: 32px;
		height: 32px;
	}

	&.small svg {
		width: 28px;
		height: 28px;
	}

	&.x-small svg {
		width: 20px;
		height: 20px;
	}

	&.large {
		.errorIdent {
			width: 16px;
			height: 16px;
			right: -8px;
			top: -8px;
		}

		svg {
			width: 56px;
			height: 56px;
		}
	}
	&.page {
		div:nth-of-type(1) {
			margin-top: 12px;
		}
		div:nth-of-type(2) {
			margin-top: -16px;

			svg path {
				fill: white;
			}
		}
	}
}

.errorIdent {
	@include background("pink-primary");
	width: 12px;
	height: 12px;
	position: absolute;
	right: -6px;
	top: -6px;
	border-radius: 100%;
}
