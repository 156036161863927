@import "../../../global.scss";

.customRangeContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@include color("grey-200", $background: true);
	@include radius;
	margin-right: 16px;
	padding: 8px 16px;

	.icon {
		@include color("grey-600", $svg: true);
	}

	.text {
		margin-left: 8px;
		text-wrap: nowrap;
		@include color("grey-600");
		@include noSelection();
	}
}

.dateIntervalContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@include color("grey-200");
	@include radius;
	padding: 8px 16px;
	background: #fff;
	@include border($color: "grey-300");

	.icon {
		@include color("grey-600", $svg: true);
	}

	.text {
		margin-left: 8px;
		text-wrap: nowrap;
		@include color("grey-600");
		@include noSelection();
	}
}

.rangeAlerter {
	min-width: 424px;
}

.datepicker {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 1.5rem;
	gap: 24px;

	.divider {
		@include border($color: "grey-200", $where: "top");
		padding-top: 12px;
		padding-bottom: 12px;
	}
	> span {
		padding-bottom: 1rem;
	}

	.pickersContainer {
		display: flex;
	}

	.pickerHeader {
		padding-bottom: 12px;
		@include border($color: "grey-200", $where: "bottom");
		display: flex;
		gap: 16px;
		align-items: center;
		justify-content: flex-end;
	}
	.presets {
		display: flex;
		justify-content: space-between;
		border-radius: 12px;
		padding: 4px;
		flex: 1;
		max-width: 380px;

		> div {
			padding: 5.5px 8px !important;
		}
	}
}
