@import "../../global.scss";

.header {
	display: flex;
	align-items: center;
	column-gap: 16px;
	gap: 16px;
	.nameText {
		flex: 2;
	}
	.textItem {
		min-width: 152px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
.hidden {
	visibility: hidden;
}

.columns {
	display: flex;
	gap: 32px;
	margin-bottom: 48px;
	.divider {
		@include border($where: "left");
	}
	.members {
		flex: 1;
		.addUser {
			max-width: 360px;
			display: flex;
			flex-direction: column;
			gap: 4px;
		}
		.checkboxAll {
			margin-left: 25px;
		}
		.userList {
			height: 400px;
			overflow-y: auto;
			.userBox {
				display: flex;
				align-items: center;
				@include border($radius: true);
				padding: 16px 24px;
				.userVA {
					margin-left: auto;
					margin-right: 40px;
				}
			}
		}
		.removeMultiple {
			@include color("pink-primary", $svg: true);
			display: flex;
			align-items: center;
			gap: 8px;
			position: relative;
		}
	}
	.permissions {
		display: flex;
		flex-direction: column;
		width: 390px;
	}
	.flexColumn {
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
	}
}
