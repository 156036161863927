@import "../../../../global.scss";

.sharingResultsModal {
	.socialNetworkIcons {
		column-gap: 12px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 16px;
	}
}

.scheduleSummaryModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px 40px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 24px;

	.content {
		width: 400px;
		height: 100%;
		max-height: 700px;
		min-height: 200px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		@include border($color: "grey-300", $where: "bottom");

		.networksSchedule {
			.networkSchedule {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 8px;

				&:not(:last-of-type) {
					@include border($color: "grey-300", $where: "bottom");
					margin-bottom: 8px;
				}
				.network {
					display: flex;
					gap: 8px;
					align-items: center;
				}

				.schedule {
					display: flex;
					align-items: flex-end;
					flex-direction: column;
					gap: 4px;
				}

				.scheduleButton {
					button {
						display: flex;
						flex-flow: row-reverse;
						padding: 7px 0;
					}
				}
			}
		}
	}

	.footer {
		display: flex;
		justify-content: flex-end;
		gap: 16px;
		align-items: center;

		> div {
			margin: 0;
		}
	}
}
