@use "sass:map";
@import "../../global.scss";

.autoQueueModal {
	@include background("white");
	box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.25);
	border-radius: 12px;
	z-index: map.get($zIndices, "modal-container");
	height: auto;

	.content {
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		position: relative;

		.loader {
			position: absolute;
			top: 50px;
			left: 150px;
		}
	}
	.title {
		font-size: 22px;
		font-weight: 600;
	}

	.weekContainer {
		display: flex;
		gap: 16px;

		.day {
			@include border("grey-400");
			border-radius: 8px;
			width: 40px;
			height: 40px;
			padding: 9px 0;
			text-align: center;
			font-size: 18px;
			cursor: pointer;

			&.selected {
				border: none;
				background-color: map.get($colors, "pink-primary");
				color: map.get($colors, "white");
			}
		}
	}
}
