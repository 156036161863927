@use "sass:map";
@import "../../global.scss";

.icon {
	@include click;

	display: flex;
	align-items: center;
	justify-content: center;

	&:focus {
		outline: none;
	}

	&.blue {
		@include svgPathColor("blue-primary");
	}
	&.pink {
		@include svgPathColor("pink-primary");
	}
	&.grey {
		@include svgPathColor("grey-600");
	}
	&.grey-400 {
		@include svgPathColor("grey-400");
	}
	&.white {
		@include svgPathColor("white");
	}
}

.navIcon {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.iconContainer {
		padding: 8px 0;
		@include border($color: "transparent", $width: 2px);
		cursor: pointer;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex: 1;

		&.active {
			@include border($where: "left", $color: "pink-primary", $width: 2px);
			@include color("pink-primary", $svg: true);
			.nameText {
				@include color("pink-primary", $svg: true);
			}

			&:hover {
				@include background("pink-100");
			}
		}

		&:not(.active):hover {
			.nameText {
				@include color("black");
			}
			@include background("pink-100");
			@include svgPathColor("black");
			@include color("black");
		}

		& + & {
			margin-top: 12px;
		}
	}

	.childNav {
		width: 100%;
		flex-direction: column;
		@include color("grey-600");
		display: none;
	}

	position: relative;
	.submenu {
		@include phone {
			display: none;
		}
		padding: 8px 0;
		top: 0;
		left: calc(100% - 8px);
		@include background("white");
		@include radius($radius: 12px);
		@include border($color: "grey-300", $width: 1px);
		box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.15);
		z-index: map.get($zIndices, "navbar-submenu");
		position: absolute;
		.submenuItem {
			display: flex;
			gap: 8px;
			padding: 0 16px;
			height: 40px;
			width: 190px;
			display: flex;
			align-items: center;
			&:hover {
				cursor: pointer;
				@include background("grey-200");
			}
		}
	}

	.phoneIconContainer {
		display: none;
		flex: 1;
		gap: 8px;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.desktopIconContainer {
		width: 100%;
		display: flex;
		flex: 1;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		justify-content: center;
		padding: 0 10px;
	}
	.alignLeft {
		justify-content: flex-start;
	}

	.roundContainer {
		padding: 4px;
	}
	.nameText {
		font-weight: 700;

		@include color("grey-600");
		@include phone {
			flex: 1;
		}
		@include tablet-and-desktop {
			display: none;
			&.expandedDesktop {
				display: block;
			}
		}
	}

	.navArrow {
		display: none;
	}

	@include phone {
		align-items: flex-start;
		justify-content: center;
		flex: 1;

		.iconContainer {
			margin-top: 12px;
		}

		.childNav {
			display: flex;
		}

		.roundContainer {
			margin-left: 22px;
			margin-right: 0;
			border-radius: 46px;
			@include background("grey-200");
		}

		.childNavText {
			flex: 1;
			align-items: center;
			display: flex;
			margin-left: 32px;
			font-weight: 400;
		}

		.phoneIconContainer {
			display: flex;
		}

		.desktopIconContainer {
			display: none;
		}

		.navArrow {
			margin-right: 12px;
			display: flex;
		}
	}
}

.arrow {
	height: 24px;
	width: 24px;
	transition: transform ease-in-out 0.33s;

	&.up {
		transform: rotate(180deg);
	}

	&.left {
		transform: rotate(90deg);
	}

	&.right {
		transform: rotate(-90deg);
	}
}

.menu {
	width: 32px;
	height: 32px;
}
