@font-face {
	font-family: "ProximaNova";
	src: url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Regular.eot")
		format("embedded-opentype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova";
	src: url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Regular.otf") format("opentype"),
		url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Regular.ttf") format("truetype"),
		url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Regular.svg#ProximaNova-Regular")
			format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "ProximaNova";
	src: url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Bold.eot") format("embedded-opentype");
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova";
	src: url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Bold.otf") format("opentype"),
		url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Bold.ttf") format("truetype"),
		url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "ProximaNova";
	src: url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Medium.eot")
		format("embedded-opentype");
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova";
	src: url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Medium.otf") format("opentype"),
		url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Medium.ttf") format("truetype"),
		url("https://app.clearviewsocial.com/assets/v4/fonts/ProximaNova-Medium.svg#ProximaNova-Medium")
			format("svg");
	font-weight: 600;
	font-style: normal;
}
