@use "sass:map";
@import "../../../global.scss";

.datepicker,
.dateButton {
	max-width: 380px;
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 1rem;

	.divider {
		@include border($color: "grey-200", $where: "top");
		padding-top: 12px;
		padding-bottom: 12px;
		.peakHour > span {
			display: none;
		}
	}
	> span {
		padding-bottom: 1rem;
	}
}

.dateButton {
	@include background;
	@include boxShadow;
	@include radius;
}
