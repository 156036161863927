@import "../../global.scss";

.search {
	max-width: 320px;
	width: 100%;
}

.empty {
	grid-column: 1/5;
	text-align: center;
	padding-top: 150px;
}
.modalCanva {
	overflow: auto;
	height: 100%;
	position: relative;
	.topRow {
		position: sticky;
		top: 0;
		z-index: 2;
		@include background("white");
		padding: 24px;
		padding-top: 0;
	}
	.minHeight {
		min-height: 457px;
	}
	.mediaContainer {
		display: grid;

		grid-template-columns: repeat(4, 1fr);
		@include tablet {
			grid-template-columns: repeat(3, 1fr);
		}

		@include phone {
			grid-template-columns: repeat(2, 1fr);
		}

		justify-content: center;
		gap: 32px;
		flex-wrap: wrap;

		padding: 0 48px;
		padding-bottom: 24px;

		.add {
			width: 204px;
			height: 168px;

			@include svgPathColor("grey-600");
		}
		.shimmerImg {
			@include border("10px");
			height: 142px;
			margin-bottom: 15px;
		}
		.mediaItem {
			position: relative;
			overflow: hidden;
			box-sizing: border-box;
			display: flex;
			gap: 10px;
			cursor: pointer;
			flex-direction: column;
			// max-width: 184px;
			width: 100%;

			.itemTitle {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			.imageContainer {
				position: relative;
				padding: 8px;
				height: 142px;
				@include background("grey-200");
				@include radius(10px);
			}
			.image {
				width: 100%;
				height: 100%;

				object-fit: cover;
			}
			.canvaBtn {
				position: absolute;
				display: none;
				top: 11px;
				right: 11px;
			}

			.checkbox {
				display: none;
				position: absolute;
				top: 11px;
				left: 11px;
			}
			&.selected {
				.imageContainer::after {
					content: "";
					position: absolute;
					inset: 0;
					z-index: 1;
					@include radius(10px);
					@include border($color: "pink-primary", $width: 2px);
				}

				.checkbox {
					display: block;
				}
			}

			&:hover {
				.imageContainer {
					@include background("grey-300");
				}
				.checkbox,
				.canvaBtn {
					display: block;
				}
			}
		}
	}
	.bottomRow {
		position: sticky;
		@include border($color: "grey-300", $width: 1px, $where: "top");
		bottom: 0;
		padding: 0 8px;
		background-color: white;
		z-index: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 24px;
	}
}
