@import "../../../global.scss";

.employeeAdvocacy {
	display: flex;
	flex-direction: column;
	row-gap: 32px;

	.header,
	.headerRow {
		flex-wrap: wrap;
		row-gap: 8px;
	}

	.dateRange {
		background: #fff;
		@include border($color: "grey-300");
		margin-right: 0;
	}
	.statsRow {
		display: flex;
		justify-content: space-evenly;
		margin-top: 20px;
		column-gap: 8px;

		.postStat {
			border-radius: 8px;
			color: #2e394a;
			border: 1px solid #dce0e7;
			padding: 10px 16px;
			background-color: #fff;
			flex: 1;
			font-size: 18px;
			display: flex;
			flex-direction: column;
			gap: 8px;

			.firstLine {
				display: flex;
				align-items: center;
				column-gap: 8px;
			}
			.amount {
				font-weight: 600;
				font-size: 22px;
				line-height: 100%;
			}
		}
	}

	.cardsContainer {
		display: flex;
		column-gap: 24px;
		flex-wrap: wrap;
		row-gap: 24px;

		> div {
			min-width: 500px;
		}
	}

	.sectionTitle {
		display: flex;
		gap: 16px;
		align-items: center;
		> * {
			white-space: nowrap;
		}
	}

	.card {
		margin-top: 0;
		padding: 24px 0 24px 24px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		flex: 1;
		overflow: hidden;

		@include more-items-modal;

		.cardHeader {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 24px;
		}

		.loader {
			margin: 16px auto;
		}

		.emptyMsg {
			margin: 16px auto;
			display: flex;
			justify-content: center;
		}
	}

	.visitorsTable {
		padding-right: 24px;
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow-x: auto;
		> :first-child {
			padding-bottom: 6px;
			@include border($where: "bottom", $color: "grey-300");
		}
		.visitorsItems {
			overflow: hidden auto;
			:nth-child(even) {
				&.visitorsRow {
					@include background("grey-200");
				}
			}
		}

		.visitorsRow {
			display: grid;
			grid-template-columns: 165px 165px 95px auto;
			column-gap: 16px;
			min-width: 540px;

			:first-child {
				flex: 1;
			}

			.visitorsCell {
				padding: 10px 7px 9px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.postsTable {
		flex: 1;
		overflow-y: auto;
		padding-right: 24px;
		.post {
			min-width: 530px;
			display: flex;
			align-items: center;
			padding: 4px 16px;
			grid-gap: 16px;
			justify-content: space-between;
			@include boxShadow($alpha: 0.04);
			@include radius;

			.postHeader {
				display: flex;
				align-items: center;
				column-gap: 8px;
			}
			.postStats {
				display: flex;
				flex-direction: column;
				text-align: center;
			}

			.image {
				height: 50px;
				width: 64px;
				img {
					height: 50px;
					width: 64px;
					@include radius;
					object-fit: cover;
				}
			}
			.text {
				flex: 1;
				overflow: hidden;
				max-width: 50%;
				height: 100%;
				line-height: 100% !important;
			}

			@include phone {
				flex-direction: column;
				text-align: center;
			}
		}
	}

	.buttonsContainer {
		display: flex;
		justify-content: space-between;
		padding-right: 24px;
		flex-wrap: wrap;
		align-items: center;
		row-gap: 16px;
		.buttonsGroup {
			display: flex;
			column-gap: 16px;
			justify-content: flex-end;
			flex-wrap: wrap;
			align-items: center;
			> div {
				margin-top: 0;
			}
		}
	}
	.pageControl {
		display: flex;
		column-gap: 16px;

		> * {
			white-space: nowrap;
		}
	}

	.userStatusTable {
		flex: 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-right: 24px;
		min-height: 200px;
		.list {
			align-items: flex-start;
			display: flex;
			gap: 8px;

			&.listHeader {
				padding: 20px 8px 10px 8px;
				margin: 0;
				@include border($where: "bottom", $color: "grey-300");
				.nameContainer {
					display: flex;
					flex-direction: row;
					gap: 8px;
				}
			}

			&.listItem {
				padding: 4px 8px;

				&:nth-child(even) {
					@include background("grey-200");
				}
			}

			.smallColumn {
				max-width: 50px;
			}

			.mediumColumn {
				max-width: 95px;
			}

			.largeColumn {
				min-width: 250px;
			}

			> * {
				overflow-wrap: anywhere;
				flex: 1;
			}

			@include tablet-and-phone {
				flex-direction: column;
			}
		}

		.loader {
			width: 100%;
			margin: 16px auto;
		}
	}

	.doughnutChart {
		width: 215px;
	}

	.adoptionStats {
		display: flex;
		padding: 8px 56px 16px 32px;
		column-gap: 72px;
		overflow-x: auto;
		.stats {
			display: flex;
			flex-direction: column;
			row-gap: 8px;
			flex: 1;
			.statsLine {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 8px 0;
				flex: 1;
				> * {
					white-space: nowrap;
				}
				.firstColumn {
					display: flex;
					align-items: center;
					column-gap: 16px;
				}
				.secondColumn {
					display: flex;
					flex-direction: column;
					column-gap: 16px;

					> * {
						flex: 1;
						display: block;
						text-align: right;
					}
				}
				&:not(:last-of-type) {
					@include border($where: "bottom", $color: "grey-300");
				}

				.statsColor {
					width: 16px;
					height: 16px;
					border-radius: 4px;
				}

				.label {
					display: flex;
					flex-direction: column;
				}
				.percentage {
					font-weight: 600;
					font-size: 22px;
				}
				.invitationSentMsg {
					display: flex;
					column-gap: 8px;
				}
			}
		}
	}

	.adoptionCard {
		min-width: min(100%, 795px) !important;
	}
	.pendingCard {
		min-width: 350px !important;

		.cardContent {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex: 1;
			row-gap: 8px;
		}
	}

	.sameLine {
		display: flex;
		align-items: center;
		column-gap: 16px;
	}

	.asc {
		transform: scaleY(-1);
	}
}
