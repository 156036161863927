@import "../../global.scss";

.contests {
	.header {
		display: flex;
		gap: 15px;
		flex-wrap: wrap;
		.leftHeader {
			flex: 1;
		}
		.rightHeader {
			gap: 16px;
			display: flex;
		}
	}
	.content {
		margin-top: 32px;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		@media (min-width: 600px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 1100px) {
			grid-template-columns: repeat(3, 1fr);
		}

		.empty {
			grid-column: 1 / -1;
			padding-top: 200px;
			text-align: center;
			margin: 0 auto;
		}
		gap: 16px;
	}
}
.label {
	margin-bottom: 4px;
	display: flex;
}
.contestValues {
	display: flex;
	gap: 15px;
	.rightInput {
		width: 128px;
	}
	.leftInput {
		width: 288px;
	}
	.prize {
		position: relative;
	}
}
.contest {
	min-width: 270px;
	cursor: pointer;
	padding: 32px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	position: relative;
	overflow: hidden;
	background-color: #fff;
	@include border($color: "blue-primary");
	@include radius(16px);
	&.inProgress {
		@include border($color: "pink-primary");
	}
	&.finnished {
		@include border($color: "grey-200");
		@include background("grey-200");
	}
	.kpiAchieved {
		gap: 8px;
		display: flex;
		padding: 16px 0;
	}
	.avatars {
		display: flex;
		.avatar {
			@include border($width: 2px, $color: "white");
			margin-left: -5px;
			z-index: 0;
			border-radius: 50%;
		}
		.andMore {
			@include background("black");
			box-sizing: content-box;
			color: white;
			width: 28px;
			height: 28px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.edit {
		box-sizing: content-box;
		position: absolute;
		top: 16px;
		cursor: pointer;
		display: none;
		right: 16px;
		height: 24px;
		@include background("black", 0.7);
		@include radius(99px);
		@include color("white", $svg: true);
		border: none;
		height: 16px;
		padding: 4px 8px;
	}
	&:hover {
		.edit {
			display: block;
		}
	}

	h3 {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
	}
}
