@import "../../global.scss";

.container {
	overflow: hidden;
	&.absolute {
		@include fill-absolute();
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.image {
		border-radius: 50%;
		@include spin;

		> img {
			width: 100%;
			height: 100%;
		}
	}

	&.medium .image {
		width: 75px;
		height: 75px;
	}
	&.extraSmall .image {
		width: 24px;
		height: 24px;
	}
	&.small .image {
		width: 40px;
		height: 40px;
	}
	&.large .image {
		width: 100px;
		height: 100px;
	}
	&.input .image {
		width: 40px;
		height: 40px;
	}
}
