@import "../global.scss";

.bodyContainer {
	@include background("grey-200");
	@include radius;
	max-height: 355px;
	padding: 15px;
	overflow: auto;
	margin-top: 16px;

	.cardContainer {
		padding: 24px;
	}
}
