@use "sass:map";
@import "../../global.scss";

.colors {
	.colorText {
		@each $name, $color in $colors {
			&.#{$name} {
				@include color($name);
			}
		}
	}

	.colorBackground {
		width: 100px;
		height: 20px;

		@each $name, $color in $colors {
			&.#{$name} {
				@include background($name);
			}
		}
	}
}

.icons {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	//grid-auto-rows: 32px;
	gap: 12px;

	div {
		padding: 10px;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
}

.socialLogos {
	display: grid;
	gap: 12px;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	justify-items: center;
}
