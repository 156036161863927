@use "sass:map";
@import "../global.scss";

.quickAddContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	.shortLink {
		svg {
			transform: rotate(-45deg);
		}
	}
}
.links {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.menu {
	overflow-y: auto;
	@include background;
	border-radius: 12px;
	min-width: 100px;
	@include boxShadow;
	z-index: map.get($zIndices, "modal-container");
	padding: 16px;
	.quickMenuLink {
		position: relative;
		display: flex;
		padding: 0 16px;
		gap: 16px;
		height: 40px;
		@include color("black");
		text-decoration: none;
		align-items: center;
		@include radius($radius: 12px);
		&:hover {
			@include background("grey-100");
		}
	}
	.disabled {
		@include color("grey-400", $svg: true);
		cursor: default;
		pointer-events: none;
	}
}

.quickAdd {
	margin-left: auto;
	@include phone {
		display: none;
	}
}
