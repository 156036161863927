@import "../../global.scss";

.itemHeader {
	display: flex;
	align-items: center;
	gap: 24px;

	.rssIcon {
		width: 40px;
	}
}

.rssData {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.dotw {
	> label {
		font-weight: 600;
		justify-content: center;
	}
}
