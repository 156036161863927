@use "sass:map";
@import "../../global.scss";

.card {
	box-shadow: none;
	@include border("grey-300");
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	padding: 16px;

	.companyStats {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.socialNetworkIcons {
			display: flex;
			flex-direction: row;
			column-gap: 8px;
		}
	}
	.dateSelector {
		padding: 0;
		background: transparent;
	}
	.logo {
		display: flex;
		justify-content: center;
		position: relative;

		> img {
			max-width: 150px;
			height: auto;
		}

		.edit {
			position: absolute;
			right: 0;
			top: 0;
			padding: 4px;
			display: none;
			cursor: pointer;

			@include color("white", $svg: true, $background: "grey-600");
			@include radius(50%);
		}

		&:hover .edit {
			display: initial;

			&.disabled {
				display: none;
			}
		}

		&.disabled {
			cursor: unset;
		}
	}

	.analytics {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		div {
			flex: 1;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			&:not(:last-child) {
				@include border($where: "right", $color: "grey-200");
			}

			h4 {
				&:last-of-type {
					@include color("blue-primary");
					word-break: break-all;
					max-width: 110px;
				}
			}
		}
	}
}
