@import "../../../global.scss";

.container {
	display: flex;
	gap: 8px;

	.timeContainer,
	.clockContainer {
		@include border();
		font-size: 14px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		cursor: pointer;
		text-align: center;
		padding: 2px;

		span {
			text-align: center;
		}

		&.disabled {
			cursor: not-allowed;
			@include color($color: "grey-400", $background: "grey-100");
		}
	}

	.clockContainer {
		flex-shrink: 0;
		:first-child {
			padding: 2px;
		}

		div {
			border-width: 0;
		}
	}

	.timeSpan {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 4px;
	}
}
