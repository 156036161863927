@use "sass:map";
@import "../../global.scss";

.container {
	display: flex;
	max-width: 800px;
	flex-direction: column;
	flex: 1;

	.cardContainer {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include color("grey-800");
	}

	.groupSubscription {
		display: flex;
		gap: 24px;
		flex-direction: column;
	}

	.groupSearchableSelect {
		@include border($radius: 16px, $color: "grey-300", $width: 1px);
	}

	.profileInfo {
		flex-direction: row;
		display: flex;
	}

	.info {
		align-items: flex-start;
		margin-left: 16px;
		flex: 1;
		flex-direction: column;
		display: flex;
	}
}

.peakTime {
	//display: flex;
}

.socialMediaContainer {
	display: flex;
	flex-direction: column;
	flex: 1;

	.socialIcon {
		display: flex;
		flex: 1;
		gap: 8px;
		align-items: center;
	}

	.actionsContainer {
		display: flex;
		flex: 1;
		align-items: flex-start;

		.identifier {
			margin-left: 8px;

			&.active {
				@include color("blue-primary");
			}
		}
	}
}

.sharingTimeSlotsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px;
	padding: 8px 0;

	.sharingTimeSlot {
		@include radius;
		@include background("blue-100");
		@include svgPathColor("black");
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 2px 8px;
		gap: 8px;
		align-items: center;
	}
}

.hourPicker {
	margin-left: 16px;
	margin-right: 16px;
}

.profilePicModalContainer {
	margin-top: 8px;
	margin-bottom: 8px;
	display: flex;
	flex-direction: row;
	gap: 32px;
	align-items: center;
	justify-content: space-evenly;

	.textContainer {
		align-items: center;
		justify-content: center;
	}
}

.profilePicContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	@include focus;
	gap: 8px;

	.uploadImageContainer {
		justify-content: center;
		align-items: center;
		height: 72px;
		width: 72px;
		@include radius(50%);
		@include border($radius: false, $color: "grey-400", $width: 1px);
		@include color($color: "grey-600", $svg: true, $background: "grey-200");

		&.social {
			object-fit: cover;
			@include border($radius: false, $color: "blue-primary", $width: 1px);

			&:hover {
				@include background("grey-500");
				opacity: 0.65;
			}
		}
	}
}
