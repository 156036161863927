@import "../../../global.scss";

.datePicker {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 4px;

	.weekContainer {
		min-height: 1.8rem;
		cursor: pointer;
		width: 30px;
		margin: auto;
		&.selectedDay {
			@include background("blue-100");
			border-radius: 50%;
			> span {
				@include noSelection();
			}
		}

		&.disabledDay {
			cursor: not-allowed;
			@include color("grey-500");
		}
	}

	> * {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.dayContainer {
	cursor: default;
}
