.hourValues {
	display: flex;
	.hourEdit {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		input {
			width: 30px;
			padding: 0;
			margin: 0;
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
	.delete {
		margin-left: 10px;
	}
}
