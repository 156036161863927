@import "../../global.scss";

.container {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 20px;
	text-align: center;
}

.networksContainer {
	gap: 12px;
	padding-top: 12px;

	.networks {
		@include background("grey-100");
		@include boxShadow;
		@include radius();
		align-items: center;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		gap: 8px;
		height: 120px;
		justify-content: center;
		width: 120px;

		&.active {
			@include border($color: "blue-primary", $width: 2px);
			background: none;
		}
		&.expired {
			@include border($color: "pink-primary", $width: 2px);
			background: none;
		}
	}
}
