@use "sass:map";
@import "../../../global.scss";
@include more-items-modal;
.pendingUsersModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px 0;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;

	> :first-child {
		padding: 0 16px;
	}
	.content {
		width: 900px;
		height: 100%;
		max-height: 700px;
		min-height: 500px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;
	}

	.toolbar,
	.items {
		padding: 0 16px;
	}

	.items {
		overflow-y: auto;
	}
}
