@import "../global.scss";

.container {
	@include tablet-and-desktop {
		display: grid;
		grid-template-rows: 72px 1fr;
	}
	@include phone {
		display: flex;
		flex-direction: column;
	}
	position: relative;
	width: 100%;
	height: 100%;
	@include background("grey-100");
}

.scrollContent {
	overflow: auto;
}

.page {
	@include fix-min;
	width: 100%;
	margin: 0 auto;
	height: 100%;
	padding: 24px 48px;
	@include phone {
		padding: 20px;
	}

	&.sidenav {
		overflow: hidden;
		margin: 0;
		padding: 0;
	}
	&:not(.fullWidth):not(.sidenav) {
		// 1280 + 48 + 48 = 1376
		max-width: 1376px;
	}
}

.topRow {
	padding: 0 48px;
	gap: 40px;
	display: flex;
	align-items: center;
	@include background("white");
	@include tablet-and-desktop {
		@include border($where: "bottom");
	}
	@include phone {
		@include background("grey-100");
		padding: 24px 20px 0;
		font-size: 22px;
	}
	.tab {
		display: flex;
		height: 100%;
		align-items: center;
		font-size: 18px;
		&:hover {
			text-decoration: none;
		}
		@include color("grey-600");
		@include phone {
			&:not(.active) {
				display: none;
			}
		}
	}
	.active {
		@include color("pink-primary");
		@include border($where: "bottom", $color: "pink-primary");
	}
	.title {
		font-weight: 700;
		align-items: center;
		@include tablet-and-desktop {
			font-size: 24px;
		}
	}
}

.return {
	@include svgPathColor("blue-primary");
}
