@import "../../../global.scss";

.datePicker {
	display: grid;
	max-width: 376px;
	grid-template-columns: repeat(7, 1fr);
	row-gap: 4px;

	&.months,
	&.years {
		grid-template-columns: repeat(4, 1fr);
	}
	.weekContainer {
		min-height: 51px;
		min-width: 53px;
	}
	.monthContainer {
		min-height: 95px;
		min-width: 94px;
	}
	.weekContainer,
	.monthContainer {
		cursor: pointer;

		&.today {
			> span,
			&.betweenDates > span {
				@include color("pink-primary");
			}
		}

		&.betweenDates > span {
			@include color("grey-600");
		}
		&.startDay,
		&.endDay,
		&.selectedDay {
			> span {
				@include color("white");
				@include noSelection();
			}
		}
		&.hoverDay > span {
			@include color("black");
		}

		&.hoverDay,
		&.endDay,
		&.startDay {
			position: relative;
			&:after {
				content: "";
				position: absolute;
				height: 44px;
				width: 44px;
			}
			> span {
				z-index: 1;
			}
		}

		&.betweenDates,
		&.betweenHover {
			position: relative;
			&:before {
				content: "";
				position: absolute;
				height: 44px;
				width: 44px;
			}
			> span {
				z-index: 1;
			}
		}

		&.startDay:after {
			@include background("blue-primary");
			width: 44px;
			border: none;
			@include radius(50%);
		}
		&.endDay:after {
			@include background("blue-primary");
			width: 44px;
			@include radius(50%);
		}

		&.hoverDay:after {
			width: 44px;
			@include background("white");
			@include border($color: "blue-primary");
			@include radius(50%);
		}

		&.betweenHover:before {
			width: calc(100% - 2px);
			left: 1px;
			right: 1px;
			@include border("blue-100", $where: [ "top", "bottom" ], $style: dashed, $color: "blue-100");
			@include radius(0);
		}
		&.betweenDates:before {
			width: 100%;
			@include background("blue-100");
			@include radius(0);
		}
		&.startDay:not(.endDay, .hideBefore):before {
			content: "";
			position: absolute;
			width: 50%;
			left: 50%;
			@include background("blue-100");
			@include radius(0);
			height: 44px;
		}

		&.endDay:not(.startDay, .hideBefore):before {
			content: "";
			position: absolute;
			width: 50%;
			right: 50%;
			@include background("blue-100");
			@include radius(0);
			height: 44px;
		}

		&.hideBefore:before {
			display: none;
		}
		&.halfDottedLeft:before {
			content: "";
			display: block;
			position: absolute;
			width: calc(50% - 1px);
			right: 50%;
			left: 0;
			@include background("white");
			@include border("blue-100", $where: [ "top", "bottom" ], $style: dashed, $color: "blue-100");
			@include radius(0);
			height: 44px;
		}
		&.halfDottedRight:before {
			content: "";
			position: absolute;
			display: block;
			width: calc(50% - 1px);
			left: 50%;
			right: 0;
			@include background("white");
			@include border("blue-100", $where: [ "top", "bottom" ], $style: dashed, $color: "blue-100");
			@include radius(0);
			height: 44px;
		}

		&.disabledDay {
			cursor: not-allowed;
			pointer-events: none;
			@include color("grey-500");
		}
	}

	> * {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.dayContainer {
	cursor: default;
}
