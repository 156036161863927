@use "sass:map";
@import "../../global.scss";

.items {
	display: flex;
	flex-direction: column;
	min-height: 300px;

	.row {
		display: flex;
		flex-direction: row;
		padding: 6px 8px;
		gap: 8px;

		@include tablet-and-phone {
			flex-direction: column;
		}

		&:nth-child(odd) {
			@include background("grey-100");
		}

		&:first-child {
			background: none;
			padding-bottom: 16px;
			@include border($where: "bottom");
		}

		.rowItem {
			display: flex;
			flex-flow: row nowrap;
			flex: 1;
			overflow-wrap: anywhere;
			align-items: center;
		}
	}
}
