@import "../../../global.scss";

.companyAnalyticsPageStyles {
	display: flex;
	flex-direction: column;
	gap: 32px;
	.container {
		display: flex;
		flex-direction: column;
		gap: 24px;

		> div {
			margin: 0;
		}
	}

	.header {
		display: flex;
		flex-wrap: wrap;
		gap: 8px 16px;
	}
	.cardsContainer {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;

		> div {
			min-width: 550px;
			flex: 1;
			max-width: 100%;
			margin: 0;
		}
	}

	.card {
		padding: 24px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		flex: 1;
		overflow: hidden;
		@include border($color: "grey-300");
		@include more-items-modal;

		.cardHeader {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 24px;

			.headerTitle {
				gap: 16px;
			}
		}

		.loader {
			margin: 16px auto;
		}

		.emptyMsg {
			margin: 16px auto;
			display: flex;
			justify-content: center;
		}
	}

	.postsCard {
		padding: 32px 40px;
		row-gap: 8px;

		h3 {
			padding: 0 8px;
		}
		.postsContainer {
			display: flex;
			gap: 32px;
		}
		.postsTable {
			flex: 1;
			overflow-y: auto;
			padding: 8px;

			.listHeader {
				margin-bottom: 8px;
				font-weight: 600;
				display: block;
			}
			.post {
				//width: 530px;
				display: flex;
				align-items: center;
				padding: 4px 16px;
				grid-gap: 16px;
				@include boxShadow($alpha: 0.1);
				@include radius;

				.postHeader {
					display: flex;
					align-items: center;
					column-gap: 8px;
				}
				.statsContainer {
					display: flex;
					align-items: center;
					column-gap: 16px;
					width: 50%;
				}
				.postStats {
					display: flex;
					flex-direction: column;
					text-align: center;
					min-width: 14%;
				}

				.image {
					height: 50px;
					width: 64px;
					img {
						height: 50px;
						width: 64px;
						@include radius;
						object-fit: cover;
					}
				}
				.text {
					flex: 1;
					overflow: hidden;
					//max-width: 50%;
					height: 100%;
					line-height: 100% !important;
				}

				@include phone {
					flex-direction: column;
					text-align: center;
				}
			}
		}
	}

	.sameLine {
		display: flex;
		align-items: center;
		column-gap: 16px;
	}
}
