@use "sass:map";
@import "../../../../global.scss";

.collectionContainer {
	display: flex;
	flex-direction: column;
	width: 800px;
	position: relative;
	background: #fff;
	overflow: hidden auto;
	max-height: calc(100% - 50px);
	@include radius($radius: 16px);
	@include boxShadow($alpha: 0.04);
	.collectionInfo {
		.revoke {
			margin-right: 8px;
		}
		padding: 32px 40px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		.recipients {
			padding-right: 8px;
		}

		.collectionInfoShedule {
			display: flex;
			gap: 40px;
			.scheduleDate {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
			}
		}
		.collectionMessage {
			div {
				padding: 0;
			}
		}

		.buttonsContainer {
			display: flex;
			gap: 16px;
			align-items: center;
		}
	}
	.actions {
		padding: 24px 40px;
		position: sticky;
		bottom: 0;
		@include background("white");
		@include border($where: "top", $color: "grey-300");
	}
}
.posts {
	flex: 1;
	@include background("grey-100");
	padding: 16px 40px 30px 40px;
	@include border($where: "top", $color: "grey-300");
	display: flex;
	flex-direction: column;
	gap: 32px;
	.postData {
		display: flex;
		gap: 16px;
		.black {
			@include color("black");
			font-weight: 600;
		}
	}
	.noMargin {
		margin-top: 0;
		margin-bottom: 0;
	}
	.postGroup {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.emv {
			margin-bottom: 24px;
			margin-left: 32px;
			margin-top: 8px;
			display: flex;
			align-items: center;
			gap: 4px;
			@include color("grey-600");
		}
		.post {
			@include background("white");
			@include border($color: "grey-300");
			@include radius($radius: 16px);
			@include boxShadow($alpha: 0.04);
			display: flex;
			align-items: center;
			flex-direction: column;
			.media {
				img,
				video {
					width: 80px;
					height: 58px;
					object-fit: cover;
					@include radius($radius: 4px);
				}
			}
			.bulkComment {
				white-space: pre-wrap;
			}

			.text {
				display: flex;
				flex-direction: column;
				overflow: hidden;
				gap: 8px;
				flex: 1;

				.title {
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					-webkit-line-clamp: 1;
				}

				.comment {
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					white-space: pre-wrap;
				}
			}
			.collapseBtn {
				cursor: pointer;
			}
			.postTop {
				height: 56px;
				display: flex;
				padding: 12px 24px;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				@include border($where: "bottom", $color: "grey-300");
			}
			.postBottom {
				display: flex;
				width: 100%;
				flex-direction: column;
				padding: 24px;
				gap: 16px;
				.urlBox {
					@include background("grey-200");
					@include radius(8px);
					width: 100%;
					padding: 16px;
					display: flex;
					gap: 16px;
				}
				.postLink {
					@include color("grey-600");
				}
			}
			.revoke {
				display: flex;
				align-items: center;
				justify-content: end;
			}
		}

		.closed {
			flex-direction: row;
			gap: 16px;
			padding: 16px;
		}
	}
}

.shuffledImages {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
}
