@use "sass:color";
@import "../../../../global.scss";

@mixin removeBtn {
	width: 24px;
	height: 24px;
	@include color("black", $svg: true);
	@include background("white", $alpha: 0.5);
	border-radius: 50%;
	position: absolute;
	cursor: pointer;
}

@mixin container {
	display: flex;
	flex-direction: column;
	@include border($width: 1px, $color: "grey-300", $radius: 10px);
	@include boxShadow($alpha: 0.04);
	background: #fff;

	&:active,
	&:focus-within {
		@include border($width: 1px, $color: "blue-primary", $radius: 10px);
	}
	.body {
		display: flex;
		flex-direction: column;
		padding: 16px 24px;
		gap: 16px;

		.comment {
			padding: 0;
			* {
				padding: 0 !important;
				line-height: 150%;
			}
		}
	}
	.headerContainer {
		padding: 8px 24px 7px 24px;
		@include border($color: "grey-300", $where: "bottom", $width: 1px);
	}
	.topToolbarContainer {
		padding: 24px 24px 0;
	}
	.metadata {
		display: flex;
		flex: 1;

		&:not(.onlyMedia) {
			padding: 16px;
			background: #eef1f5;
			@include radius($radius: 8px);
		}

		.info {
			flex: 1;
			display: flex;
			flex-direction: column;
			position: relative;

			.removeBtn {
				top: 0;
				right: 0;
				visibility: hidden;
				@include removeBtn;
			}
		}
		&:hover .info {
			.removeBtn {
				visibility: visible;
			}
		}
		.title,
		.description {
			position: relative;
			padding-left: 6px;

			> div,
			* > div {
				margin: 0;
			}

			* > button {
				padding: 3px 0;
				height: 24px;
			}

			.aiPicker {
				background: color.change(#fff, $alpha: 0.6);
			}
		}

		.title {
			textarea {
				font-size: 14px;
				font-weight: 700;
				height: unset !important;
			}
		}

		.description {
			textarea {
				font-size: 12px;
				height: unset !important;
			}
		}

		.mediaContainer {
			display: flex;
			flex-direction: column;
			gap: 24px;

			.images {
				display: flex;
				flex-wrap: wrap;
				gap: 7px;
				.addImageBtn {
					width: 96px;
					height: 96px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 8px;
					cursor: pointer;
					position: relative;

					&.active,
					&:hover {
						background: #f3fbff;
					}

					&.disabled {
						@include background("grey-200");
						opacity: 0.6;
						cursor: auto;
					}

					@include border($color: "grey-400", $style: dashed, $width: 1px);
					@include radius($radius: 4px);

					.icon {
						width: 24px;
						height: 24px;
						@include background("grey-500");
						@include radius($radius: 50%);
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.loading {
						position: absolute;
						height: 43%;
						width: 43%;
					}
				}
			}

			.multiImageInfo {
				display: flex;
				gap: 8px;
			}
		}

		.url {
			a {
				@include color("grey-600");
				font-size: 14px;
				font-weight: 400;
			}

			padding-left: 16px;
			position: relative;
			&:hover {
				.removeUrl {
					visibility: visible;
				}
			}
			.removeUrl {
				position: absolute;
				right: 3px;
				top: -3px;
				visibility: hidden;

				@include svgPathColor("black");
				display: flex;
				border-radius: 12px;
				background-color: #fff;

				&:hover {
					@include svgPathColor("grey-600");
				}
			}
		}
	}
}

.editor {
	.container {
		@include container;
		gap: 0;

		.commentInput {
			.commentToolbar {
				padding-top: 16px;
			}

			textarea {
				font-size: 16px;
				line-height: 150%;
			}
		}
		.metadata {
			.title,
			.description {
				position: relative;
				padding-left: 6px;

				* > textarea,
				* > input {
					background: #eef1f5;
				}

				.aiPicker {
					visibility: hidden;
					position: absolute;
					bottom: 8px;
					right: 3px;
				}
				.videoAiPicker {
					right: 30px;
				}

				&:hover {
					.aiPicker {
						visibility: visible;
					}
				}
			}
		}
	}
}

.sharePreview {
	@include container;

	.headerContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		gap: 8px;

		.network {
			display: flex;
			gap: 8px;
			padding: 6px 0 7px 0;
		}
	}

	.body {
		.metadata {
			.info {
				display: flex;
				flex-direction: column;
				gap: 8px;

				.title {
					padding-left: 16px;
					font-weight: 700;
				}

				.description {
					padding-left: 16px;
					font-size: 12px;
				}
			}
		}
	}
}

.module {
	display: flex;
	flex-direction: column;
}

.suggestion {
	text-align: center;

	&.green {
		@include color("green");
	}
	&.blue {
		@include color("blue-primary");
	}
	&.yellow {
		@include color("yellow");
	}
	&.pink {
		@include color("pink-primary");
	}
}

.container {
	display: flex;
	gap: 8px;
}

@keyframes loadingBlocks {
	0% {
		@include background("grey-300");
	}
	100% {
		@include background("blue-primary");
	}
}

.blocks {
	display: flex;
	gap: 4px;
	align-items: center;

	> div {
		height: 4px;
		width: 15px;
		transition: background-color 1s ease-in-out;
		@include radius(2px);
		@include background("grey-300");

		&.green {
			@include background("green");
		}
		&.blue {
			@include background("blue-primary");
		}
		&.yellow {
			@include background("yellow");
		}
		&.pink {
			@include background("pink-primary");
		}
		&.loading {
			animation: loadingBlocks 0.25s;
		}
		@for $i from 1 through 5 {
			&:nth-child(#{$i}n) {
				animation-delay: #{$i * 0.25}s;
			}
		}
	}
}
.previewContainer {
	position: relative;
	.canvaBtn {
		position: absolute;
		cursor: pointer;
		top: 23px;
		left: 8px;
		visibility: hidden;
	}

	&:hover {
		.removeBtn,
		.canvaBtn {
			visibility: visible;
		}
	}
}

.media {
	@include radius($radius: 4px);
	overflow: hidden;
	display: flex;
	align-content: center;
	position: relative;
	.removeBtn {
		@include removeBtn;
		visibility: hidden;
		top: 8px;
		right: 8px;
	}
	.canvaBtn {
		position: absolute;
		cursor: pointer;
		top: 8px;
		left: 8px;
		visibility: hidden;
	}

	&:hover {
		.removeBtn,
		.canvaBtn {
			visibility: visible;
		}
	}
	img,
	video {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	img {
		cursor: pointer;
	}
}

.preview {
	width: 100%;
	margin-top: 15px;
}

@keyframes skeleton-loading {
	0% {
		@include background($color: "grey-100");
	}
	100% {
		@include background($color: "grey-200");
	}
}

@mixin skeleton {
	@include radius($radius: 6px);
	animation: skeleton-loading 1s linear infinite alternate;
}

.postSkeleton {
	padding: 0 40px;

	.headerContainer {
		padding: 8px 0 7px 0;
		display: flex;
		justify-content: space-between;
		.title {
			height: 28px;
			width: 100px;
			@include skeleton;
		}
	}

	.buttons {
		height: 28px;
		width: 250px;
		@include skeleton;
	}

	.body {
		height: 250px;

		.commentInput {
			height: 25px;
			width: 350px;
			@include skeleton;
		}

		.metadata {
			height: 80px;
			@include skeleton;
		}
	}

	.skeletonToolbar {
		display: flex;
		gap: 34px;
		padding: 24px;
	}
}

.border {
	@include border($color: "grey-400");
}

.canvaContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	.canvaStep {
		display: flex;
		align-items: center;
		gap: 16px;
		.dot {
			width: 24px;
		}
	}
}
.linkMessage {
	margin-left: 40px;
}
