@use "sass:map";
@import "../../global.scss";

.container {
	display: flex;
	flex-direction: column;

	.labelContainer {
		cursor: pointer;
	}
}

.content {
	@include background("white");
	box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.25);
	border-radius: 12px;
	z-index: map.get($zIndices, "modal-container");
	min-width: 282px;
	height: auto;
}
