@use "sass:map";
@import "../../global.scss";

.card {
	@include border("grey-300");

	display: flex;
	flex-direction: column;
	row-gap: 16px;
	padding: 16px;

	.loading {
		margin: 16px auto;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 8px;
		position: relative;

		.iconButton {
			position: absolute;
			right: -8px;
			top: -8px;
		}
	}

	.items {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}
	.dateSelector {
		padding: 0;
		background: transparent;
	}
	.row {
		@include border($where: "top");

		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 16px 0;

		.name {
			flex-grow: 1;
		}
	}
}
