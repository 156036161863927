@import "../global.scss";

.container {
	display: grid;
	grid-template-columns: 216px 1fr;

	.scrollContent {
		overflow: auto;
	}

	.sidenav {
		@include background("white");
		display: flex;
		flex-direction: column;
		padding: 16px;
		gap: 16px;

		// overflow: auto;

		> .item {
			@include color("grey-600");
			padding: 8px;

			&.active {
				@include background("pink-100");
				@include color("pink-primary");
				@include radius(8px);

				&:hover {
					@include color("pink-dark");
				}
			}

			&:not(.active):hover {
				@include background("grey-100");
				@include color("grey-600");
				@include radius(8px);
			}
		}

		a {
			font-weight: 600;
			text-decoration: none;
		}
	}

	.page {
		// overflow: auto;
		margin: 0 auto;
		padding: 20px 24px;
		max-width: 1064px;
		width: 100%;
		height: 100%;
		position: relative;
	}
	.fitContent {
		width: fit-content;
	}
	.fullWidth {
		max-width: unset;
	}
}
