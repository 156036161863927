@import "../../global.scss";

.container {
	display: inline-flex;
	align-items: center;
	flex-direction: row;
	padding-left: 8px;
	padding-right: 3px;
	height: 26px;
	line-height: 21px;
	gap: 3px;
	min-width: 0;
	max-width: 300px;

	&:focus-within {
		@include outline();
	}

	@include border($radius: true);

	@each $color in [ "blue", "pink", "black" ] {
		$light: if($color == "black", "grey-400", "#{$color}-100");
		$main: if($color == "black", "black", "#{$color}-primary");
		&.#{$color} {
			@include color($color: "white", $background: $main, $borderColor: $main, $svg: "white");
			&.outline {
				@include color($color: $main, $background: $light, $borderColor: $main, $svg: $main);

				&:not(.disabled) .delete:hover {
					@include background($color: $main, $alpha: 0.2);
				}
			}
		}
	}

	&.disabled {
		@include color($color: "grey-600", $background: "grey-400", $borderColor: "grey-400", $svg: "grey-600");
		&.outline {
			@include color($color: "grey-600", $background: "grey-400", $borderColor: "grey-600", $svg: "grey-600");
		}
	}

	&:not(.disabled) .delete {
		cursor: pointer;
	}

	.delete {
		padding: 3px;
		border-radius: 50%;
	}
	&:not(.disabled) .delete:hover {
		@include background($alpha: 0.35);
	}

	h5 {
		margin: 0;
		white-space: nowrap;
		@include trim(1);
	}
}
