@import "../../global.scss";

.contest {
	.header {
		display: flex;
		gap: 15px;
		flex-wrap: wrap;
		.leftHeader {
			flex: 1;
			display: flex;
			align-items: center;
		}
		.rightHeader {
			.space {
				margin-top: 0;
			}
			display: flex;
		}
	}

	.content {
		margin-top: 32px;
		display: flex;
		flex-direction: column;
		.backButton {
			width: fit-content;
		}
		.empty {
			padding-top: 200px;
			text-align: center;
		}
		gap: 16px;
		flex-wrap: wrap;
		.contestInfo {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			@include tablet-and-phone {
				flex-direction: column;
				align-items: flex-start;
			}

			h3 {
				overflow: hidden;
				max-width: 500px;
				word-break: break-all;
			}
			.data {
				display: flex;
				gap: 24px;
				align-items: center;
				.bullet {
					border: 2px solid #a1adbf;
					margin: auto;
					height: 0px;
					border-radius: 50%;
				}
			}
		}
		.leaderboard {
			display: flex;
			flex-direction: column;
			gap: 16px;
			.leaderboardItem {
				.rank {
					text-align: center;
					h3 {
						@include color("grey-500");
					}
					&.qualified {
						h3 {
							@include color("black");
						}
					}
				}
				.user {
					width: 264px;
				}
				.metrics {
					flex: 1;
					display: flex;
					gap: 24px;
					.metric {
						flex: 1;
						height: 72px;
						padding: 0 16px;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						white-space: nowrap;
					}
					.active {
						@include border($color: "grey-300");
						@include radius(8px);
						position: relative;
					}
					.complete {
						@include border($color: "pink-primary");
					}
					.star {
						position: absolute;
						top: -10px;
					}
					.pink {
						@include color("pink-primary", $svg: true);
					}
					.grey {
						@include color("grey-300", $svg: true);
					}
					.metricLabel {
						display: flex;
						align-items: center;
					}
				}
				.reward {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 128px;
				}
				.rightSide {
					display: flex;
					gap: 24px;
					align-items: center;
					flex: 1;
					@include phone {
						flex-direction: column;
						gap: 12px;
					}
				}

				box-shadow: 0px 2px 10px 0px rgba(46, 57, 74, 0.04);
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding: 16px 32px;
				gap: 24px;
				@include background($color: "white");
				@include radius(16px);
			}
		}
	}
}
.modal {
	width: fit-content;
	overflow: hidden;
	padding: 24px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 24px;

	.rewardTypes {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 24px;
		.inputDisabled {
			@include background("grey-200");
		}
		.rewardOption {
			height: 75px;
			width: 544px;
			position: relative;
			display: flex;
			padding: 0 24px;
			align-items: center;
			@include border("grey-400");

			input {
				margin-top: 0;
				margin-right: 8px;
				appearance: none;
				@include border($color: "grey-500", $width: 2px, $radius: true);
				width: 20px;
				height: 20px;
				border-radius: 50%;
			}
			input:hover:not(:disabled) {
				@include border($color: "pink-primary", $width: 2px);
			}
			input:checked {
				@include border($color: "pink-primary", $width: 2px);
				position: relative;
				&:after {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					@include border($color: "pink-primary", $width: 5px, $radius: true);
				}
			}

			.tremendousOption {
				display: flex;
				flex: 1;
				align-items: center;
				.tremendousLogoContainer {
					margin-left: auto;
					.tremendousLogo {
						display: flex;
					}
				}
			}
		}
	}
}
.integrationsLink {
	display: flex;
	align-items: center;
}
