@use "sass:map";
@import "../../global.scss";

.postPreviewModal {
	width: fit-content;
	overflow: auto;
	z-index: 1;
	position: relative;

	.content {
		display: flex;
		width: min(80vw, 1280px);
		height: min(100vh, 700px);

		.imageContainer {
			flex: 1;
		}

		.details {
			display: flex;
			flex-direction: column;
			width: 344px;
			padding: 48px 24px 16px 16px;

			.header {
				display: flex;
				flex-direction: column;
				gap: 8px;

				.avatar {
					* {
						font-size: 18px;
						font-weight: 600;
					}
				}

				.createdAt {
					font-size: 12px;
					@include color($color: "black");
				}
			}
			.body {
				width: 304px;
				flex: 1;
				word-wrap: break-word;
			}

			.toolbar {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				> div {
					margin: 0;

					button {
						padding: 0;
					}
				}

				.clicks {
					padding: 7px 0;
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
	}
}
