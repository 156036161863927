@import "../../global.scss";

.container {
	height: 100%;
	display: flex;
	flex-direction: column;

	.tabs {
		margin-top: 40px;
		display: flex;
		align-items: flex-start;
		@include border($where: "bottom");
	}

	.mediaContainer {
		flex-grow: 1;
		overflow-y: auto;
	}
}
