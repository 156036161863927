@use "sass:map";
@use 'sass:color';
@import "../../global.scss";

.container {
	width: 100%;
	position: relative;

	canvas {
		margin-top: 15px;
	}

	.downloadButton {
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0;
		transition: opacity 0.1s ease-in-out;
	}

	&:hover {
		.downloadButton {
			opacity: 1;
		}
	}
}

:export {
	@each $name, $value in $colors {
		#{$name}: $value;
		transparent-#{$name}: color.change($value, $alpha: 0.5);
	}
}
