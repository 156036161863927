@import "../../global.scss";

.container {
	display: block;
	padding: 4px 16px;
	font-size: 14px;
	font-weight: 600;
	min-height: 26px;
	@include trim;

	&.small {
		min-height: 20px;
		padding: 3px 10px;
		font-size: 12px;
	}

	@include radius();

	@each $color in [ "blue", "pink", "black" ] {
		$light: if($color == "black", "grey-400", "#{$color}-100");
		$main: if($color == "black", "black", "#{$color}-primary");
		&.#{$color} {
			@include color($color: $main, $background: $light, $borderColor: none, $svg: "white");
			&.outline {
				@include border();
				@include color($color: $main, $background: $light, $borderColor: $main, $svg: $main);
			}
			&.solid {
				@include color($color: "white", $background: $main, $borderColor: none, $svg: "white");
			}
		}
	}

	h5 {
		margin: 0;
	}
}
