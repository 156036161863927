@use "sass:map";
@import "../global.scss";

.container {
	@include fill-absolute;
	z-index: map.get($zIndices, "modal-container");
}

.backdrop {
	@include background("black", $alpha: 0.6);
}

$modalMaxHeight: 750px;

.hidden {
	visibility: hidden;
	z-index: -1;
}

.confirm {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modalContainer {
	@include fill-absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	.backdrop {
		@include fill-absolute;
		@include background("black", $alpha: 0.6);
	}
}

.modal {
	@include background;
	@include radius;
	margin: auto;
	min-height: 225px;

	&:not(.fit-content) {
		min-width: 400px;
		max-width: min(100%, 500px);
		max-height: min(100%, $modalMaxHeight);
		overflow: auto;
		padding: 24px 16px;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.close {
		position: absolute;
		top: 24px;
		right: 16px;
	}

	&.large {
		min-width: min(80%, var(--max-width, 80%));

		.modalContents {
			max-height: calc($modalMaxHeight - 140px);
		}
	}

	&.medium {
		min-width: 40%;

		.modalContents {
			max-height: calc($modalMaxHeight - 120px);
		}
	}

	&.fit-content {
		max-height: 100%;
		.modalContents {
			width: fit-content;
			max-height: 100%;
			.body {
				overflow: hidden;
				width: fit-content;
			}

			&.hasFooter {
				.body {
					padding: unset;
					border: unset;
				}
			}
		}
	}

	.header {
		margin-right: 40px;
	}

	.modalContents {
		display: grid;
		grid-template-rows: 1fr;
		gap: 24px;
		max-height: calc($modalMaxHeight - 56px);
		overflow: hidden;

		&.hasFooter {
			grid-template-rows: 1fr max-content;
			flex: 1;

			.body {
				@include border($where: "bottom", $color: "grey-300");
				padding-bottom: 16px;
			}
		}

		.body {
			overflow: auto;
		}
	}
}
