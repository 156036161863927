@import "../../../global.scss";

.container {
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
		align-items: center;
		gap: 16px;

		.headerText {
			flex: 1;
		}

		.inputRow {
			flex: 2;
			flex-wrap: wrap;
			row-gap: 8px;
			justify-content: right;
		}

		@include phone {
			flex-direction: column;
		}
	}

	.itemContainer {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
		gap: 10px;

		.userContainer {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: row;
			flex: 1;
		}

		.valuesContainer {
			display: flex;
			align-items: center;
			flex: 1;
		}

		.valueContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 1;
		}

		.infoContainer {
			flex-direction: row;
			display: flex;
			gap: 2px;
			align-items: center;
			@include svgPathColor("blue-primary");
		}

		.avatar {
			display: flex;
			position: relative;
			flex: 2;

			.hint {
				position: absolute;
				bottom: 0;
				@include color("blue-100", $background: true);
				@include radius;
				width: 68px;
				height: 26px;
				display: flex;
				align-items: center;
				padding-left: 4px;
				padding-right: 4px;
				justify-content: center;

				.text {
					text-align: center;
				}
			}
		}

		.badgeContainer {
			gap: 10px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex: 2;
		}

		@include tablet-and-phone {
			flex-direction: column;
			align-items: unset;
		}
	}
}

.modal {
	flex: 1;
	display: flex;
	flex-direction: column;

	.dateContainer {
		display: flex;
		gap: 8px;
	}
}
