@import "../../global";

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	.centerText {
		position: absolute;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.title {
		text-align: center;
		font-size: 22px;
	}
}
