@use "sass:map";
@import "../../global.scss";

$avatarSizes: (
	"extraSmall": (
		height: 28px,
		width: 28px,
		fontSize: 12px,
		fontWeight: 600,
		lineHeight: 16px,
	),
	"small": (
		height: 38px,
		width: 38px,
		fontSize: 14px,
		fontWeight: 600,
		lineHeight: 18px,
	),
	"medium": (
		height: 42px,
		width: 42px,
		fontSize: 14px,
		fontWeight: 600,
		lineHeight: 18px,
	),
	"large": (
		height: 48px,
		width: 48px,
		fontSize: 16px,
		fontWeight: 600,
		lineHeight: 21px,
	),
	"extraLarge": (
		height: 72px,
		width: 72px,
		fontSize: 22px,
		fontWeight: 700,
		lineHeight: 29px,
	),
);

@mixin avatarSize() {
	@each $name, $props in $avatarSizes {
		&.#{$name} {
			height: map-get($props, height);
			width: map-get($props, width);
			font-size: map-get($props, fontSize);
			font-weight: map-get($props, fontWeight);
			line-height: map-get($props, lineHeight);
		}
	}
}

.container {
	display: flex;
	align-items: center;

	.component {
		@include avatarSize;
		@include background("black");
		@include color("white");
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		position: relative;
		flex-shrink: 0;

		.experience {
			position: absolute;
			bottom: 0;
			@include color("blue-100", $background: true);
			@include radius;
			height: 26px;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			padding-left: 4px;
			padding-right: 4px;
			justify-content: center;
		}

		&.disabled {
			@include background("grey-400");
		}

		.opacity {
			display: none;
		}

		&.clickable {
			cursor: pointer;

			&:hover {
				@include background("grey-500");

				.opacity {
					@include fill-absolute;
					@include background;
					@include radius(50%);
					display: initial;
					opacity: 0.65;
				}
			}
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}

		span {
			@include noSelection();
		}

		.editIcon {
			border-radius: 50%;
			width: 24px;
			height: 24px;
			right: 0;
			top: 0;
			position: absolute;
			@include background("pink-primary");
			@include svgPathColor("white");
		}
	}

	.name {
		margin-left: 8px;
		display: flex;
		flex-direction: column;
	}
}
