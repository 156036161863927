@import "../../global.scss";

.errorMessagePage {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	gap: 24px;
	margin: auto;
	height: 100%;

	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.modal {
	padding: 24px 15px 24px 5px;
}

.smallGap {
	gap: 16px;
}

.errorMessage {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100%;
	width: 100%;
}

.uhoh {
	margin: 0px;
	@include color("black");
}
