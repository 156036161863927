@import "../../global.scss";

.container {
	display: flex;
	align-items: center;
	flex-direction: column;

	.image {
		@include size;
		object-fit: contain;
	}
}
