@import "../../../global.scss";

.teams,
.slack {
	grid-template-columns: 200px 1fr 40px;
	gap: 16px;
}

.integrations {
	display: flex;
	flex-direction: column;
	.header {
		column-gap: 120px;
		display: flex;
		justify-content: flex-start;
		gap: 8px;
		align-items: center;

		> h4 {
			min-width: 200px;
		}
	}
	.description {
		max-width: 528px;
	}

	.inputContainer {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.label {
		@include color("grey-600");
	}

	.centeredContent {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	.teams,
	.slack {
		grid-template-columns: 200px 1fr 40px;
		gap: 16px;
	}

	.headingPersonal {
		padding-top: 8px;
	}

	.integrationsRow {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 16px 0;
		gap: 16px;

		.integrationsCard {
			width: 520px;
			display: flex;
			flex-direction: column;
			padding: 24px;
			.bottom {
				margin-top: auto;
				padding-top: 16px;
			}
			.openErrors {
				display: inline-flex;
				align-items: center;
			}
		}
	}
}

.salesforceModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px 0 24px 24px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;

	.content {
		width: 615px;
		height: 100%;
		min-height: 400px;
		max-height: 700px;
		display: flex;
		flex-direction: column;
		.header {
			margin-bottom: 16px;
			justify-content: space-between;
			width: 100%;
			> * {
				flex: 1;
			}
		}
		.fields {
			overflow-y: auto;
			height: calc(100% - 130px);
		}

		.salesforceRow {
			display: flex;
			align-items: center;
			column-gap: 16px;

			.field {
				width: 248px;
				height: 40px;
				border-radius: 16px;
				padding: 11px 16px;
				background: #eef1f5;
				display: flex;
				justify-content: space-between;
				span {
					display: block;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.fieldSelection {
				width: 248px;
				overflow: hidden;

				button {
					width: 100%;
					display: flex;
					justify-content: space-between;
				}

				&.empty > button {
					@include color("grey-500");
				}
			}

			.required {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				&:before {
					content: "*";
					font-size: 44px;
					line-height: 1;
					height: 24px;
					@include color("red");
				}
			}
		}

		.addFieldBtn {
			padding-right: 24px;
			margin-top: 16px;
			button {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}

	.errorsContent {
		width: 610px;
		height: 100%;
		.errors {
			height: 100%;
			overflow-y: auto;
			padding-right: 24px;
			.log:not(:last-of-type) {
				@include border($where: "bottom", $color: "grey-300");
				padding-bottom: 16px;
				margin-bottom: 16px;
			}
		}
	}

	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 24px 24px 0;
	}
	.footer {
		margin-right: 24px;
		display: flex;
		justify-content: space-between;
		padding-top: 16px;
		@include border($where: "top", $color: "grey-300");
		.info {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}

.hubspotModal {
	width: fit-content;
	overflow: hidden;
	padding: 24px 0 24px 24px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;

	.content {
		width: 615px;
		height: 100%;
		min-height: 400px;
		max-height: 700px;
		display: flex;
		flex-direction: column;
		.header {
			margin-bottom: 16px;
			justify-content: space-between;
			width: 100%;
			> * {
				flex: 1;
			}
		}
		.fields {
			overflow-y: auto;
			height: calc(100% - 130px);
		}

		.hubspotRow {
			display: flex;
			align-items: center;
			column-gap: 16px;

			.field {
				width: 248px;
				height: 40px;
				border-radius: 16px;
				padding: 11px 16px;
				background: #eef1f5;
				display: flex;
				justify-content: space-between;
				span {
					display: block;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.fieldSelection {
				width: 248px;
				overflow: hidden;
				&.error > button {
					@include border($color: "red");
				}
				button {
					width: 100%;
					display: flex;
					justify-content: space-between;
				}

				&.empty > button {
					@include color("grey-500");
				}
			}

			.required {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				&:before {
					content: "*";
					font-size: 44px;
					line-height: 1;
					height: 24px;
					@include color("red");
				}
			}
		}

		.addFieldBtn {
			padding-right: 24px;
			margin-top: 16px;
			button {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}

		.errorWarning {
			@include color("red");
			margin-top: 10px;
		}
	}

	.errorsContent {
		width: 610px;
		height: 100%;
		.errors {
			height: 100%;
			overflow-y: auto;
			padding-right: 24px;
			.log:not(:last-of-type) {
				@include border($where: "bottom", $color: "grey-300");
				padding-bottom: 16px;
				margin-bottom: 16px;
			}
		}
	}

	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 24px 24px 0;
	}
	.footer {
		margin-right: 24px;
		display: flex;
		justify-content: space-between;
		padding-top: 16px;
		@include border($where: "top", $color: "grey-300");
		.info {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}
