@use "sass:map";
@import "../global.scss";

$mobile-menu-height: 64px;

.container {
	width: 100%;
	height: 100%;
	display: flex;

	@include phone {
		flex-direction: column;
		overflow: auto;
	}
}

.page {
	@include fix-min;
	flex: 1;
}

.nav {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	@include tablet-and-desktop {
		padding-top: 8px;
		flex-direction: column;
		@include border($where: "right");
	}
	@include phone {
		@include border($where: "bottom");
	}
	position: relative;
	.arrowCollapseDesktop {
		margin-left: auto;
		margin-right: 8px;
		@include color("grey-400", $svg: true);
		@include phone {
			display: none;
		}
		&:hover {
			@include color("blue-primary", $svg: true);
		}
	}
	.rotate {
		transform: rotate(180deg);
	}

	&.fullWidth {
		.logo {
			padding: 0 16px;
			img {
				width: 100%;
			}
		}
	}
	.logo {
		margin-bottom: 8px;
		img {
			height: 40px;
			width: 40px;
			object-fit: contain;
		}

		// margin: 16px 0;
		@include phone {
			margin: 13px 20px;
		}
	}

	.menu {
		margin: 16px 10px;
		margin-left: auto;

		@include tablet-and-desktop {
			display: none;
		}
	}

	.items {
		@include tablet-and-desktop {
			width: 100%;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}

		@include phone {
			display: none;

			&.open {
				display: initial;
				position: absolute;
				right: 0;
				top: $mobile-menu-height + 44;
				width: 100%;
				z-index: map.get($zIndices, "menu");
			}
		}
	}
	.account {
		position: relative;
	}
	.avatar {
		display: flex;
		margin-top: 0;
		gap: 8px;
		padding: 10px 12px;
		margin-top: 5px;
		// margin-bottom: 24px;
		font-weight: 700;
		align-items: center;
		cursor: pointer;

		.avatarContainer {
			width: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 32px;
			margin: 4px;
		}

		.avatarText {
			display: none;
			margin-left: 12px;
			align-items: center;
			justify-content: center;
		}
		&:hover {
			@include tablet-and-desktop {
				@include background("pink-100");
			}
		}

		@include phone {
			user-select: none;
			pointer-events: none;
			margin: 12px 12px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;

			.avatarText {
				display: flex;
			}
		}
	}

	.submenu {
		padding: 8px 0;
		bottom: 0;
		left: calc(100% - 8px);
		@include background("white");
		@include radius($radius: 12px);
		@include border($color: "grey-300", $width: 1px);
		box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.15);
		z-index: map.get($zIndices, "navbar-submenu");
		position: absolute;
		.submenuItem {
			padding: 0 16px;
			height: 40px;
			width: 190px;
			display: flex;
			gap: 8px;
			align-items: center;
			font-weight: 400;
			@include color("black");
			&:hover {
				cursor: pointer;
				text-decoration: none;
				@include background("grey-200");
			}
			&.smallScreen {
				//display: none if height is larger or equal with 780px
				@media screen and (min-height: 781px) {
					display: none;
				}
			}
		}
	}

	.itemsContainer {
		flex: 1;

		.rounded {
			@include background;
			display: flex;
			flex-direction: column;
			height: 100%;
			padding-bottom: 24px;
		}

		@include phone {
			display: flex;
			flex-direction: column;
			margin: 20px;

			.rounded {
				@include radius;
				@include background;
				@include boxShadow(0.04);
			}
		}
	}
	.bottomContainer {
		margin-top: auto;
	}

	.bottom {
		& + .bottom {
			margin-top: 5px;
		}
		@media screen and (max-height: 780px) {
			display: none;
		}
	}
}

.fullWidth {
	width: 168px;
	@include phone {
		width: 100%;
	}
}

.menuAccount {
	width: auto;
	@include border;
	@include phone {
		display: none;
	}
}
.menuAccountContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 8px 0;
	.separator {
		@include border($where: "top", $color: "grey-300");
	}
	.menuLink {
		cursor: pointer;
		&:hover {
			text-decoration: none;
			@include background("grey-200");
		}
		display: flex;
		gap: 8px;
		padding: 0 16px;
		height: 40px;
		.menuLinkText {
			display: flex;
			align-items: center;
		}
	}
	@include color("grey-600");
}
