@import "../../../../global.scss";

.contentLibrary {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	@include radius;
	@include background($color: "grey-200");
	height: 100%;
	width: 430px;

	.card {
		display: flex;
		flex-direction: column;
		padding: 16px;

		.date {
			@include color("grey-600");
		}

		.image {
			width: 112px;
			@include radius();
			margin-right: 20px;
			object-fit: contain;
		}

		.content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.cardTitle {
				word-break: break-word;
			}
			.avatar {
				@include color("grey-600");
			}
		}
	}

	.title {
		text-align: center;
		margin-bottom: 16px;
		width: 100%;
		padding: 8px;
		font-weight: 600;
	}

	.selection,
	.addRssBtn {
		padding: 0 16px;
	}

	.items {
		margin-top: 10px;
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 0 16px;
	}
}
