@import "../../../global.scss";

.container {
	display: grid;
	grid-template-columns: 340px 174px 1fr;

	.imageContainer {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 16px;

		img {
			@include border($radius: true);
			object-fit: contain;
			width: 100%;
		}
	}
}
.removeLogoModal {
	padding-top: 36px;
}
