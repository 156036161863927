@import "../../global.scss";

.padding {
	padding: 0 40px;
	height: 24px;
}

.inputHeader {
	padding-bottom: 16px;
	flex-wrap: wrap;
	row-gap: 8px;
}

.noUsers {
	display: flex;
	flex-grow: 1;

	> div {
		@include background;
		@include radius;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		padding: 40px;
		margin: auto;
	}
}

.header {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: space-between;

	.item {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 12px 0;
		text-align: center;
		width: 110px;
	}

	.networks {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 200px;

		.icons {
			align-items: center;
			display: flex;
			gap: 13px;
			justify-content: center;
		}
	}
	.profile {
		display: flex;
		align-items: center;
		width: 280px;
		.checkbox {
			margin-right: 32px;
		}
	}
}

@include tablet {
	.header {
		.checkbox {
			margin-left: 16px;
		}
		.item.disabled {
			display: none;
		}
		.networks .icons {
			gap: 10px;
		}
	}
}

@include phone {
	.header {
		.checkbox {
			margin-left: 20px;
		}
		.item,
		.networks {
			width: 100%;
		}
	}
}

.confirm {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.domains {
	@include background($color: "grey-200");
	@include radius;
	padding: 16px 24px;
}

.groupSelect {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
}
