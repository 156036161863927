@use "sass:map";
@import "../../global.scss";

@include more-items-modal;

.header {
	display: flex;
	align-items: center;
	gap: 16px;
}

.syncModal {
	gap: 16px;

	> div > h3 {
		padding-left: 8px;
	}

	.syncModalContent {
		width: 100%;
		flex: 1;
		padding: 0 8px;
		gap: 16px;
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;
	}
	.openErrors {
		display: inline-flex;
		align-items: center;
	}

	.syncStatus {
		padding-inline: 30px;
		@include background("blue-100");
		@include radius(8px);
		height: 88px;
		padding: 16px 32px 16px 32px;
		gap: 8px;
	}

	.toolbar {
		padding: 0 8px;
	}
}
