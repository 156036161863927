@import "../../global.scss";

.container.container {
	min-height: 231px;
	@include border("grey-300");
	display: flex;
	flex-direction: column;
	padding: 16px;
	gap: 16px;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.items {
		display: flex;
		flex-direction: column;
		position: relative;
		flex-grow: 1;
	}

	.item {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		.number {
			font-variant-numeric: tabular-nums;
		}
		&:not(:first-child) {
			margin-top: 8px;
			padding-top: 8px;
		}

		& ~ .item {
			@include border($where: "top");
		}
	}

	.score {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: auto;
	}

	.badges {
		display: flex;
		width: 52px;
		overflow-x: hidden;

		.badge {
			position: relative;
			z-index: 3;

			&:nth-child(2) {
				left: -10px;
				z-index: 2;
			}

			&:nth-child(3) {
				left: -20px;
				z-index: 1;
			}

			img {
				width: 24px;
				height: 24px;
			}
		}
	}
}
