@import "../../global.scss";

.empty {
	text-align: center;

	img {
		height: 412px;
	}
}

.collectionList {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	column-gap: 24px;
	margin: 24px 0 24px 0;
	row-gap: 16px;
	width: 100%;

	@include tablet-and-phone {
		display: flex;
		flex-direction: column;
	}
}

.collectionsHeader,
.postsHeader {
	flex-wrap: wrap;
	gap: 8px 16px;

	.headerRow {
		margin-left: 0 !important;
		flex-wrap: wrap;
		gap: 8px 16px;
		> div {
			margin-left: 0 !important;
		}
	}
}

.showAll {
	padding-right: 24px;
}
