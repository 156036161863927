@import "../global.scss";

$tabHeight: 48px;
$tabVMargin: 24px;

.noScroll {
	max-height: calc(100% - ($tabHeight + 2 * $tabVMargin));
	display: flex;
}

.tabs {
	display: flex;
	margin: $tabVMargin 0;
	@include border($where: "bottom");

	@include phone {
		display: none;
	}

	&.blankTabs {
		margin: 10px 16px;
	}

	.input {
		margin-left: auto;
		padding-bottom: 8px;
	}

	> .tab {
		padding-bottom: 20px;
		height: $tabHeight;
		@include color("grey-600");
		white-space: nowrap;
		position: relative;
		cursor: pointer;

		::before {
			display: block;
			content: attr(title);
			font-weight: bold;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
		&.blankTab {
			height: 36px;
			& ~ .blankTab {
				margin-left: 22px;
			}
		}

		&:focus {
			outline: none;
		}
		&:focus-visible > span {
			@include outline;
		}

		& ~ .tab {
			margin-left: 40px;
		}

		::after {
			content: "";
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			@include border($where: "bottom");
			@include radius(999px);
		}

		&:hover {
			@include color("black");
			::after {
				@include color("black", $borderColor: true);
			}
		}

		&.active {
			@include color("pink-primary");
			font-weight: 600;

			::after {
				@include color("pink-primary", $borderColor: true);
			}

			&:hover {
				@include color("pink-dark");
				::after {
					@include color("pink-dark", $borderColor: true);
				}
			}
		}

		&.disabled {
			pointer-events: none;
			@include color("grey-400", $borderColor: "grey-400");
		}
	}

	a {
		text-decoration: none;
		font-weight: 400;
	}
}
