@use "sass:map";
@import "../../../global.scss";

.row {
	display: flex;
	align-items: center;
	gap: 20px;
	padding: 10px;
	cursor: pointer;

	p {
		flex: 1;
	}

	&.highest,
	&.replicated,
	&.widespread {
		background-color: rgba(255, 0, 0, 0.2);
	}
	&.high,
	&.partially_replicated,
	&.significant {
		background-color: rgba(255, 165, 0, 0.2);
	}
	&.medium,
	&.unable_to_test,
	&.limited {
		background-color: rgba(255, 255, 0, 0.2);
	}
	&.low,
	&.not_replicated,
	&.minimal {
		background-color: rgba(144, 238, 144, 0.2);
	}
	&.lowest {
		background-color: rgba(0, 128, 0, 0.2);
	}
}

.result {
	font-weight: 700;
	padding: 20px;
	&.highest,
	&.replicated,
	&.widespread {
		background-color: rgba(255, 0, 0, 0.4);
	}
	&.high,
	&.partially_replicated,
	&.significant {
		background-color: rgba(255, 165, 0, 0.4);
	}
	&.medium,
	&.unable_to_test,
	&.limited {
		background-color: rgba(255, 255, 0, 0.4);
	}
	&.low,
	&.not_replicated,
	&.minimal {
		background-color: rgba(144, 238, 144, 0.4);
	}
	&.lowest {
		background-color: rgba(0, 128, 0, 0.4);
	}
}

.radioGroup {
	display: flex;
	align-items: center;
	gap: 10px;
	min-width: 150px;

	label {
		font-weight: bold;
		cursor: pointer;
	}

	input[type="radio"] {
		margin: 0;
		cursor: pointer;
	}
}
