@import "../../global.scss";

.chartCard {
	@include border($color: "grey-300");

	.chartHeader {
		padding: 0 8px;
	}
	.headerTitle {
		gap: 16px;
		flex-wrap: wrap;

		h3 {
			white-space: nowrap;
		}
		span {
			flex: 1;
			display: block;
			width: fit-content;
			white-space: nowrap;
		}
	}
}
.chartContainer {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 15px;
}
