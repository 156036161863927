@import "../../../global.scss";

.dotwContainer {
	flex-direction: column;
}

.container {
	display: flex;
	gap: 4px;
	align-self: flex-start;
}

.day {
	@include border($radius: 50%, $color: "grey-600");
	@include color("grey-600");
	@include noSelection();

	position: relative;
	display: flex;
	line-height: 100%;
	align-items: center;
	justify-content: center;
	font-weight: 600;

	&.selected {
		@include color("blue-primary", $borderColor: true, $background: "blue-100");
	}
	&:not(.disabled) {
		cursor: pointer;
	}
	&.disabled.onChange {
		@include color("grey-400", $borderColor: true);
		&.selected {
			@include background("grey-200");
		}
	}
}
