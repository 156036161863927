@import "./global.scss";
@import "./styles/fonts.scss";

html {
	height: 100vh;
	width: 100vw;
	box-sizing: border-box;
}

body,
button,
textarea {
	font-family: "ProximaNova", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	margin: 0;
	width: 100%;
	height: 100%;
	font-size: 14px;
	@include color("black");
	font-weight: 400;
}

input,
textarea {
	@include color("black");
	&::placeholder {
		@include color("grey-600");
		opacity: 1;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
	line-height: 130%;
}

h1 {
	font-size: 28px;
	font-weight: 700;
}

h2 {
	font-size: 24px;
	font-weight: 700;
}

h3 {
	font-size: 22px;
	font-weight: 700;
}

h4 {
	font-size: 18px;
	font-weight: 600;
}

h5 {
	font-weight: 600;
	font-size: 16px;
}

h6 {
	font-weight: 600;
	font-size: 14px;
}

#root {
	width: 100%;
	height: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

a {
	@include color("blue-primary");
	@include focus;
	border-radius: 2px;
	font-weight: 600;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

hr {
	@include color("grey-200", $borderColor: true, $background: true);
	margin: 16px 0;
}

code {
	@include color("black-dark", $background: "grey-200");
}

@keyframes pathRotate {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-540deg);
	}
	100% {
		transform: rotate(-1080deg);
	}
}

.space + .space {
	margin-top: 16px;
}

// CS Agent - Salesforce button
.embeddedServiceHelpButton .helpButton .uiButton {
	background-color: #e72b81 !important;
	font-family: "Proxima Nova", sans-serif !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
	outline: 1px solid #e72b81 !important;
}
