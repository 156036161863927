@import "../../global.scss";

.container {
	margin: 16px 0;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.collapse {
	&.outer {
		@include border($color: "grey-300", $where: "bottom");

		&:first-child {
			@include border($color: "grey-300", $where: "top");
		}
		padding: 16px;
	}

	&.inner {
		padding: 5px 0;
		.header {
			padding: 0 24px;
			justify-content: flex-start;

			&.selected {
				padding-left: 0;
			}

			.dot {
				padding: 0 8px;
			}

			> span {
				flex-grow: 1;
			}
		}
	}
}

.item {
	margin: 10px 0;
	padding: 0 32px;
	display: flex;
	justify-content: flex-start;
	gap: 8px;

	.itemContent {
		display: flex;
		flex: 1;
		cursor: pointer;
	}

	.dot {
		@include svgPathColor("transparent");
		padding-right: 8px;
	}

	.checkIcon {
		padding-right: 8px;
		visibility: hidden;
	}

	.calendarIcon {
		display: flex;
		align-items: center;
		cursor: pointer;

		&.scheduled {
			@include svgPathColor("blue-primary");
		}
	}

	&.selected {
		@include color("blue-primary");
		.dot {
			@include svgPathColor("blue-primary");
		}
		.checkIcon {
			visibility: visible;
		}
	}

	.bookmark {
		margin-left: auto;
		&.bookmarked {
			@include svgPathColor("blue-primary");
		}
	}

	&.disabled .itemContent {
		cursor: default;
	}

	&:not(.disabled):hover {
		@include color("blue-primary", $background: "blue-100");
	}
}

.bookmarkSeparator {
	margin-bottom: 0;
}
