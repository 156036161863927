@import "../../global.scss";

.analyticsCard {
	@include border($color: "grey-300");
	@include radius(8px);
	display: flex;
	flex-direction: column;
	padding: 10px 16px;
	flex: 1;
	.titleContainer {
		display: flex;
		align-items: center;
		gap: 7px;

		.subTitleContainer {
			display: flex;
			gap: 2px;
			align-items: center;
			.caretUp {
				svg {
					transform: rotate(180deg);
				}
			}
		}
	}

	.loader {
		> div {
			width: 50px;
			height: 50px;
		}
	}
}

.analyticsCards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	@include tablet {
		grid-template-columns: repeat(2, 1fr);
	}
	@include phone {
		grid-template-columns: none;
	}
	gap: 8px;

	> :global(.space) + :global(.space) {
		margin-top: 0;
	}
}
