.container {
	display: flex;
	gap: 24px;
	flex-direction: column;

	.legal {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
	}

	.legalTitle {
		display: flex;
		cursor: pointer;
	}

	.legalArrow {
		margin-left: 8px;
	}

	.label {
		font-weight: 600;
		line-height: 130%;
		font-size: 18px;
	}
}
