@import "../../global.scss";

.container {
	margin: 16px 0px;
	max-height: 400px;
	overflow: auto;
	padding: 15px;

	.item {
		@include border($where: "bottom");
	}
}

.addSection {
	padding: 0 16px;
}
