@import "../../global.scss";

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	> div {
		max-width: 300px;
	}

	img {
		@include phone {
			width: 80%;
		}
	}
}
