@use "sass:map";
@import "../../global.scss";

.container {
	display: flex;
	flex-direction: column;
	padding-top: 40px;

	.headerButtons {
		display: flex;

		.filterButtons {
			width: 50%;

			> div {
				flex: 1;
			}
		}

		.selectButtonContainer {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.selectedItemsText {
				margin-right: 24px;
			}

			.selectItemsContainer {
				flex: 1;
			}

			@include phone {
				flex-direction: column;
			}
		}
	}

	.mediaContainer {
		flex-grow: 1;
		overflow-y: auto;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 24px;
		padding-top: 24px;
		padding-bottom: 24px;
		width: 100%;
		height: 100%;
		min-height: 200px;
		.uploadingFile {
			position: absolute;
			background: white;
			height: 100%;
			width: 100%;
		}

		.selectedDetailView {
			grid-column: 1 / -1;
			@include border($color: "grey-400", $radius: true, $width: 2px);
			@include boxShadow;
		}
		.disabled {
			pointer-events: none;
		}

		.mediaItem {
			width: 100%;
			@include border($color: "grey-400", $radius: true, $width: 2px);
			@include boxShadow;
			position: relative;
			height: 168px;
			overflow: hidden;
			display: flex;

			.disabledOverlay {
				background: rgba(0, 0, 0, 0.3);
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 1;
				inset: 0;
				&::after {
					position: absolute;
					content: "Already selected";
					color: white;
					font-weight: bold;
					display: flex;
					inset: 0;
					justify-content: center;
					align-items: center;
				}
			}

			&.uploadItem {
				> div {
					width: 100%;
				}
			}

			&.previewCard {
				cursor: pointer;
			}

			&.detailedCard {
				grid-column: span 4 / span 1;
				min-height: 341px;
				display: flex;
				flex-direction: column;

				@include tablet {
					grid-column: span 3 / span 1;
				}

				@include phone {
					grid-column: span 2 / span 1;
				}

				.card {
					display: flex;
					flex-direction: column;
					flex: 1;

					.header {
						display: flex;
						margin-bottom: 16px;

						.headerTitle {
							flex: 1;
						}
					}

					.body {
						display: flex;
						flex: 1;

						.placeholder {
							flex-direction: column;
							display: flex;

							&.imageContainer {
								margin-right: 24px;

								@include tablet-and-phone {
									height: 20%;
									margin-right: 0;
									margin-bottom: 24px;
								}
							}

							&.inputContainer {
								display: flex;
								justify-content: flex-end;
								align-items: flex-end;

								@include desktop {
									flex: 1;
								}
							}
						}

						.inputs {
							display: flex;
							flex-direction: column;

							&.info {
								flex: 1;
							}
						}

						@include tablet-and-phone {
							flex-direction: column;
						}
					}
				}
			}

			&.selected {
				@include border($color: "pink-primary", $radius: true, $width: 4px);
				padding: 0;

				.mediaItemCheckbox {
					display: flex;
				}

				.previewTitle {
					display: flex;
				}
			}

			.mediaItemCheckbox {
				display: none;
				position: absolute;
				top: 8px;
				left: 8px;
				z-index: 2;
			}

			.previewTitle {
				display: none;
				position: absolute;
				bottom: 8px;
				left: 8px;
			}

			.upload {
				flex: 1;
				@include svgPathColor("grey-600");
			}

			&:hover:not(.disabled),
			&:focus:not(.disabled) {
				outline: none;
				@include border($color: "blue-primary", $radius: true, $width: 4px);

				&.detailedCard {
					@include border($color: "pink-primary", $radius: true, $width: 4px);
				}

				.mediaItemCheckbox {
					display: flex;
				}

				.previewTitle {
					display: flex;
				}

				.uploadText {
					bottom: 6px;
				}
			}
		}

		.image {
			max-height: 230px;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.video {
			max-height: 230px;
			z-index: 1;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.processing {
			position: absolute;
			display: flex;
			flex-direction: column;
			padding: 8px;
			color: white;
			justify-content: flex-end;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);

			align-items: center;
			inset: 0;
			text-align: center;
			z-index: 1;
			gap: 8px;
		}

		@include tablet {
			grid-template-columns: repeat(3, 1fr);
		}

		@include phone {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
