@import "../../global.scss";

.container {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.headerText {
		display: flex;
		flex-direction: column;
	}

	.header {
		display: flex;
		gap: 16px;
		justify-content: space-between;
		flex-direction: column;

		.divider {
			margin-bottom: 32px;
		}
	}

	.card {
		display: flex;
		gap: 36px;
		flex-direction: column;

		.selectMarketingContainer {
			display: flex;
			gap: 16px;
			align-items: center;

			&.checkboxInputRow {
				align-items: flex-start;
			}
		}

		.checkboxGroup {
			flex-direction: column;
			:global(.space) + :global(.space) {
				margin-top: 8px;
				margin-left: 0;
			}
		}
	}

	.smallCard {
		@include border();
		display: flex;
		flex-direction: column;
		gap: 16px;
		flex: 1;
	}

	.grid {
		flex: 1;
		display: flex;
		flex-direction: column;

		> * {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		.gridHeader {
			padding: 10px 8px;
		}

		.gridRow {
			padding: 4px 8px;
		}

		.gridRow:nth-child(even) {
			@include background("grey-200");
		}
	}

	.serviceContainer {
		cursor: pointer;
		width: 56px;
		height: 56px;
		padding: 2px;
		@include border($radius: false, $color: "grey-300", $width: 2px);
		@include radius(4px);

		&.active {
			padding: 0;
			@include border($radius: false, $color: "blue-primary", $width: 4px);
			@include radius(4px);
		}

		.img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
